import React, { useMemo, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';

import { Paper, Typography, Button, Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { Plan } from '../../_redux/plans/types';

const APaper = animated(Paper);
const trans = (s: number) => `scale(${s})`;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexGrow: 1,
    },
    imageContainer: {
      position: 'relative',
    },
    imageBackground: {
      position: 'absolute',
      backgroundColor: '#f4f7fe',
      width: 90,
      height: 90,
      borderRadius: '50%',
    },
    image: {
      width: 100,
      position: 'relative',
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      marginTop: theme.spacing(2),
    },
  }),
);

interface PlanItemProps {
  plan: Plan;
  promo?: {
    interval?: number | undefined;
    count?: number | undefined;
  };
  disabled: boolean;
  handleOpen: (interval: number) => void;
  mode?: 'create' | 'upgrade';
}

const PlanItem: React.FC<PlanItemProps> = (props) => {
  const classes = useStyles();
  const {
    plan,
    // promo = {},
    disabled,
    handleOpen,
    mode = 'create',
  } = props;

  const [animProps, set] = useSpring(() => ({
    s: 1,
    config: { mass: 1, tension: 350, friction: 10 },
  }));

  const handleSubmit = useCallback(() => {
    handleOpen(plan.interval);
  }, [handleOpen, plan.interval]);

  const buttonText = useMemo(() => {
    if (mode === 'upgrade') return 'Upgrade Interval';
    if (plan.promoPrice === 0 || plan.price === 0) {
      if (plan.interval === 30) {
        return 'TRY FIRST ALERT FOR FREE';
      } else {
        return 'CREATE FREE ALERT';
      }
    }
    return 'CREATE ALERT';
  }, [mode, plan.interval, plan.price, plan.promoPrice]);

  const [priceComponent, priceColor] = useMemo<
    ['s' | 'h4', 'textPrimary' | 'textSecondary']
  >(() => {
    if (plan.promoPrice !== plan.price && mode === 'create')
      return ['s', 'textSecondary'];
    return ['h4', 'textPrimary'];
  }, [mode, plan.price, plan.promoPrice]);

  const renderPromoPrice = useMemo(() => {
    // if (plan.interval === promo.interval) {
    //   return (
    //     <Typography variant="h4" align="center" className={classes.title}>
    //       $0
    //     </Typography>
    //   );
    // }
    if (mode === 'upgrade') return null;
    if (plan.promoPrice !== plan.price) {
      return (
        <Typography variant="h4" align="center" className={classes.title}>
          ${plan.promoPrice}
        </Typography>
      );
    }
    return null;
  }, [classes.title, mode, plan.price, plan.promoPrice]);

  return (
    <APaper
      className={classes.root}
      onMouseMove={() => set({ s: 1.02 })}
      onMouseLeave={() => set({ s: 1 })}
      style={{ transform: animProps.s.to(trans) }}
      elevation={4}
    >
      {/* Image */}
      <div className={classes.imageContainer}>
        <div className={classes.imageBackground} />
        <img src={plan.icon} alt="rocket icon" className={classes.image} />
      </div>
      {/* Title */}
      <Typography variant="h5" align="center" className={classes.title}>
        {plan.title}
      </Typography>
      {/* Descriptions */}
      <Box width="100%">
        {plan.description.map((line) => (
          <Typography variant="body2" key={line} align="center" component="li">
            {line}
          </Typography>
        ))}
      </Box>
      <Box flexGrow={1} />
      {/* Price */}
      <Box display="flex">
        <Typography
          variant="h4"
          align="center"
          component={priceComponent}
          color={priceColor}
          className={classes.title}
        >
          ${plan.price}
        </Typography>
        {/* discounted price */}
        <Box ml={2}>{renderPromoPrice}</Box>
      </Box>
      {/* Submit */}
      <Box my={2}>
        <Button
          fullWidth
          //@ts-ignore
          variant={plan.buttonVariant}
          color="primary"
          onClick={handleSubmit}
          disabled={disabled}
        >
          {buttonText}
        </Button>
      </Box>
    </APaper>
  );
};

export default React.memo(PlanItem);
