import React from 'react';
import {
  Backdrop,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Link,
} from '@mui/material';
import MarketingImage from './image.jpg';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    cardRoot: {
      width: 350,
      maxWidth: '90vw',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '75vw',
      },
    },
    cover: {
      height: 350,
    },
  }),
);

export default function AppPopup() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
      <Card className={classes.cardRoot}>
        <CardActionArea>
          <CardMedia
            className={classes.cover}
            image={MarketingImage}
            title="Girl holding phone"
          />
          <CardContent>
            <Typography variant="h5" align="center">
              <Link
                href="https://apps.apple.com/in/app/campsite-monitor/id6446806154"
                target="_blank"
                underline="hover"
                rel="noreferrer"
              >
                Try Our Mobile App for a Better Experience!
              </Link>
            </Typography>
            <Typography variant="body2">
              Download our app from the{' '}
              <Link
                href="https://apps.apple.com/in/app/campsite-monitor/id6446806154"
                target="_blank"
                underline="hover"
                rel="noreferrer"
              >
                App Store
              </Link>{' '}
              today!
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Backdrop>
  );
}
