import React, { ReactElement } from 'react';

// MUI
import { Container } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

import shapesImg from '../assets/Shapes.svg';

import Header, { HeaderProps } from '../Components/Header';
import Footer from '../Components/Footer';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#E5FCFF',
      backgroundImage: `url(${shapesImg})`,
      backgroundRepeat: 'repeat-y',
      backgroundPosition: 'center',
    },
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'scroll',
      paddingBottom: theme.spacing(5),
    },
  }),
);

interface DefaultLayoutProps {
  children: ReactElement;
  headerProps?: HeaderProps;
  useContainer?: boolean;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = (props) => {
  const classes = useStyles();
  const { children, headerProps, useContainer = true } = props;

  return (
    <div className={classes.root}>
      <Header {...headerProps} />
      {useContainer ? (
        <Container className={classes.container}>{children}</Container>
      ) : (
        children
      )}
      <Footer />
    </div>
  );
};

export default React.memo(DefaultLayout);
