import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isBefore } from 'date-fns';

// MUI
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
// Icons
import AutorenewIcon from '@mui/icons-material/Autorenew';

// import mockAlerts from './__mock__';
import { listAlerts } from '../../_redux/actions/alert';

import UpgradeDialog from './UpgradeDialog';
import ListEmpty from './ListEmpty';
import ListItem from './ListItem';

const useStyles = makeStyles(theme => ({
  loadingRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  refreshRoot: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    borderBottom: '1px solid #f5f5f5',
  },
  refreshButton: {
    marginLeft: theme.spacing(1),
  },
  alertsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  card: {
    width: 275,
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  cardActions: {
    justifyContent: 'center',
  },
  title: {
    fontSize: 14,
  },
  statusContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
  },
  availableText: {
    color: '#1abc9c',
  },
  chipContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  chip: {
    margin: theme.spacing(0, 0.5, 0.5, 0),
  },
  availableChip: { color: '#1abc9c', borderColor: '#1abc9c' },
}));

const REFRESH_INTERVAL = 60;

export const ListAlert = props => {
  const classes = useStyles();
  const { alerts, listAlerts } = props;
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(null);
  const [timeToRefresh, setTimeToRefresh] = useState(0);

  const fetchAlerts = useCallback(async () => {
    setLoading(true);
    await listAlerts();
    setLoading(false);
    setTimeToRefresh(REFRESH_INTERVAL);
  }, [listAlerts]);

  useEffect(() => {
    let timeout;
    if (timeToRefresh === 0) {
      if (!document.hidden) fetchAlerts();
      else setTimeToRefresh(REFRESH_INTERVAL);
    } else timeout = setTimeout(() => setTimeToRefresh(x => x - 1), 990);
    return () => clearTimeout(timeout);
  }, [fetchAlerts, timeToRefresh]);

  const [activeAlerts, expiredAlerts] = useMemo(() => {
    let active = [],
      expired = [];
    alerts.forEach(alert => {
      if (alert.expired || isBefore(new Date(alert.startDate), Date.now()))
        expired.push(alert);
      else active.push(alert);
    });
    return [active, expired];
  }, [alerts]);

  if (loading && alerts.length === 0)
    return (
      <div className={classes.loadingRoot}>
        <CircularProgress />
        <Typography>Loading</Typography>
      </div>
    );

  if (alerts.length === 0) return <ListEmpty />;

  return (
    <>
      <div className={classes.refreshRoot}>
        <Typography color="textSecondary" variant="body2">
          Refreshing in {timeToRefresh}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.refreshButton}
          onClick={fetchAlerts}
          disabled={loading}
          startIcon={
            loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <AutorenewIcon />
            )
          }
        >
          Refresh{loading && 'ing'}
        </Button>
      </div>
      {/* Active Alerts */}
      <Typography variant="h5">Active Alerts</Typography>
      <div className={classes.alertsRoot}>
        {activeAlerts.length === 0 && <ListEmpty />}
        {activeAlerts.map(alert => (
          <ListItem
            key={alert._id}
            alert={alert}
            setEditing={setEditing}
            expired={false}
          />
        ))}
      </div>
      {/* Expired Alerts */}
      {expiredAlerts.length > 0 && (
        <>
          <Typography variant="h5">Expired Alerts</Typography>
          <Typography variant="body2" color="textSecondary">
            Note: Alert expires on check in date as some campgrounds don&apos;t
            allow same day reservations.
          </Typography>
          <div className={classes.alertsRoot}>
            {expiredAlerts.map(alert => (
              <ListItem
                key={alert._id}
                alert={alert}
                setEditing={setEditing}
                expired
              />
            ))}
          </div>
        </>
      )}
      <UpgradeDialog
        open={!!editing}
        handleClose={() => setEditing(null)}
        alertId={editing}
      />
    </>
  );
};
ListAlert.propTypes = {
  alerts: PropTypes.array.isRequired,
  listAlerts: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  alerts: state.alert.alerts,
});

const mapDispatchToProps = dispatch => ({
  listAlerts: () => dispatch(listAlerts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListAlert);
