import React, { ReactElement } from 'react';

// MUI
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

// import AuthHeader from './AuthHeader';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

import CampTentImg from '../assets/campTent.svg';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    // backgroundColor: theme.palette.background.default,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom right',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${CampTentImg})`,
    },
  },
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface AuthLayoutProps {
  children: ReactElement;
}

const AuthLayout: React.FC<AuthLayoutProps> = props => {
  const classes = useStyles();
  const { children } = props;

  return (
    <div className={classes.root}>
      {/* <AuthHeader /> */}
      <Header />
      <Container className={classes.container}>{children}</Container>
      <Footer />
    </div>
  );
};

export default React.memo(AuthLayout);
