import * as types from '../actions/stepper/types';
// import * as authTypes from '../auth/types';

const initialState = {
  activeStep: 0,
};

const stepper = (state = initialState, action) => {
  switch (action.type) {
    case types.HANDLE_NEXT:
      return { ...state, activeStep: state.activeStep + 1 };
    case types.HANDLE_BACK:
      return { ...state, activeStep: state.activeStep - 1 };
    case types.HANDLE_RESET:
      return { ...state, activeStep: 0 };
    case types.SET_ACTIVE_STEP:
      return { ...state, activeStep: action.step };
    // case authTypes.LOGOUT:
    //   return initialState;
    default:
      return state;
  }
};

export default stepper;
