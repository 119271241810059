import { Reducer } from 'redux';
import { produce } from 'immer';
import * as types from './types';

import Rocket0 from '../../assets/rocket.svg';
// import Rocket1 from '../../assets/rocket1.svg';
import Rocket2 from '../../assets/rocket2.svg';

interface PlansState {
  allPlans: types.Plan[];
  promo: types.Promo;
}

const initialPlans = [
  {
    interval: 5,
    price: 5,
    promoPrice: 5,
    title: 'Fast',
    description: [
      'Check every 5 minutes',
      'One time flat fee to create an alert',
      'Free text notification every time campground becomes available',
      'Ideal for popular campgrounds',
      'Set any check-in date in next 12 months',
    ],
    buttonVariant: 'contained',
    icon: Rocket2,
  },
  // {
  //   interval: 30,
  //   price: 2,
  //   promoPrice: 2,
  //   title: 'Regular',
  //   description: [
  //     'Check every 30 minutes',
  //     'One time flat fee to create an alert',
  //     'Text notification is sent only once when campground first becomes available',
  //     'Set any check-in date in next 2 weeks',
  //   ],
  //   buttonVariant: 'outlined',
  //   icon: Rocket0,
  // },
  // {
  //   interval: 15,
  //   price: 3,
  //   promoPrice: 3,
  //   title: 'Fast',
  //   description: ['Check every 15 minutes'],
  //   buttonVariant: 'outlined',
  //   icon: Rocket1,
  // },
];

if (process.env.NODE_ENV === 'development') {
  initialPlans.push({
    interval: 60,
    price: 0,
    promoPrice: 0,
    title: 'Slow (Dev)',
    description: ['Check every hour', 'Tips are appreciated'],
    buttonVariant: 'outlined',
    icon: Rocket0,
  });
}

const initialState: PlansState = {
  allPlans: initialPlans,
  promo: {},
};

const plans: Reducer<PlansState, types.PlansActionType> = (
  state = initialState,
  action,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.RECEIVE_PROMO_DATA:
        draft.allPlans = initialPlans.map((x) => ({ ...x })); // reset plans
        draft.promo = action.data;
        if (action.data.isFirstAlertPromoEnabled) {
          draft.allPlans.forEach((plan) => {
            if (plan.interval === action.data.firstAlertInterval) {
              plan.promoPrice = 0;
            }
          });
        }
        if (action.data.isOtherPromoEnabled) {
          draft.allPlans.forEach((plan) => {
            action.data.otherPromoPrices?.forEach((otherPromo) => {
              if (otherPromo.interval === plan.interval)
                plan.promoPrice = otherPromo.promopriceincents / 100;
            });
          });
        }
        break;
    }
  });

export default plans;
