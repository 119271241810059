import React from 'react';

// MUI
import Typography from '@mui/material/Typography';

export function CalendarHeader() {
  const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  return (
    <>
      {days.map((day, i) => (
        <Typography key={i} variant="h6" align="center">
          {day}
        </Typography>
      ))}
    </>
  );
}

export default React.memo(CalendarHeader);
