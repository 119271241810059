import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { jwtDecode } from 'jwt-decode';
import { addDays } from 'date-fns';

import { createStyles, makeStyles } from '@mui/styles';
import {
  Grid,
  TextField,
  Button,
  Typography,
  InputAdornment,
  Link,
} from '@mui/material';

import { useTypedSelector as useSelector } from '../../_redux/reducers';
import { initiateSignIn, completeSignIn, logout } from '../../_redux/auth';
import { getAuthToken, getUserPhoneNumber } from '../../_redux/auth/selectors';

const useStyles = makeStyles((theme) =>
  createStyles({
    marginTop: {
      marginTop: theme.spacing(1),
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
    textField: {
      width: 250,
      maxWidth: '100%',
    },
    link: {
      cursor: 'pointer',
    },
  }),
);

interface decodedJwt {
  aud: string;
  iss: 'https://cancellationmonitor.com/issuer';
  sub: string;
  phone_number: string;
  phone_number_verified: boolean;
  exp: number;
  iat: number;
}

const EnterPhone: React.FC = () => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();

  const accessToken = useSelector(getAuthToken);
  const userPhone = useSelector(getUserPhoneNumber);

  const [codeSentAt, setCodeSentAt] = useState(0);
  const [error, setError] = useState<null | StringKeyObject>(null);
  const editPhone = useCallback(() => setCodeSentAt(0), []);

  useEffect(() => {
    if (accessToken) {
      const decoded = jwtDecode<decodedJwt>(accessToken);
      const expired = decoded.exp < addDays(new Date(), 1).getTime() / 1000;
      if (expired) dispatch(logout());
    }
  }, [accessToken, dispatch]);

  const loginForm = useFormik({
    enableReinitialize: true,
    initialValues: { phone: userPhone, verificationCode: '' },
    validationSchema: Yup.object().shape({
      phone:
        // prettier-ignore
        process.env.NODE_ENV === 'production'
          ? Yup.string().required('Phone number is required').matches(/^[0-9]{10}$/, 'Invalid phone number')
          : Yup.string().required('Phone number is required'),
      verificationCode: codeSentAt
        ? Yup.string().required('Code is required')
        : Yup.string().notRequired(),
    }),
    onSubmit: async values => {
      setError(null);
      if (codeSentAt) {
        const error = await dispatch(
          completeSignIn(values.phone, values.verificationCode),
        );
        if (error) setError(error);
      } else {
        const [response, success] = await dispatch(
          initiateSignIn(values.phone),
        );
        if (success) setCodeSentAt(Date.now());
        else setError(response);
      }
    },
  });

  const {
    errors: formErrors,
    touched,
    values,
    getFieldProps,
    isSubmitting,
  } = loginForm;
  const getError = (field: keyof typeof loginForm.initialValues) =>
    !!(formErrors[field] && touched[field]);
  const getHelperText = (field: keyof typeof loginForm.initialValues) =>
    touched[field] && formErrors[field];

  const renderPhoneForm = () => (
    <form onSubmit={loginForm.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12} className={classes.center}>
          <TextField
            autoFocus
            required
            className={classes.textField}
            variant="outlined"
            label="Phone Number"
            placeholder="10 Digit Phone Number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+1</InputAdornment>
              ),
            }}
            error={getError('phone')}
            helperText={getHelperText('phone')}
            {...getFieldProps('phone')}
          />
        </Grid>
        <Grid item xs={12} className={classes.center}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={isSubmitting}
          >
            Get Verification Code
          </Button>
        </Grid>
      </Grid>
    </form>
  );
  const renderPhoneDisplay = () => (
    <div>
      <Typography align="center" color="textSecondary">
        Code sent to
      </Typography>
      <Typography align="center" variant="h5">
        {values.phone}
      </Typography>
      <Typography align="center" color="textSecondary">
        <Link onClick={editPhone} className={classes.link} underline="hover">
          Change Phone
        </Link>
      </Typography>
    </div>
  );

  const renderVerificationForm = () => (
    <form onSubmit={loginForm.handleSubmit}>
      <Grid container justifyContent="center" alignItems="center" spacing={1}>
        <Grid item xs={12} className={clsx(classes.marginTop, classes.center)}>
          <TextField
            required
            autoFocus
            className={classes.textField}
            variant="outlined"
            label="Verification Code"
            placeholder="Enter Verification code"
            error={getError('verificationCode')}
            helperText={getHelperText('verificationCode')}
            {...getFieldProps('verificationCode')}
          />
        </Grid>
        <Grid item xs={12} className={clsx(classes.marginTop, classes.center)}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={isSubmitting}
          >
            Verify Phone
          </Button>
        </Grid>
      </Grid>
    </form>
  );

  const renderVerifySuccess = () => (
    <div>
      <Typography
        align="center"
        variant="h6"
        className={classes.marginTop}
        color="textSecondary"
      >
        Phone Verified Successfully
      </Typography>
    </div>
  );

  return (
    <div>
      {!accessToken && !codeSentAt ? renderPhoneForm() : renderPhoneDisplay()}
      {!!(codeSentAt && !accessToken) && renderVerificationForm()}
      {accessToken && renderVerifySuccess()}
      {!!error && (
        <Typography
          variant="body2"
          align="center"
          color="error"
          className={classes.marginTop}
        >
          {error.message ?? error.error}
        </Typography>
      )}
    </div>
  );
};

export default React.memo(EnterPhone);
