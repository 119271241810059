import React, { useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';

// MUI
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

import CheckLogin from './Components/Common/CheckLogin';
import CreateAlert from './Components/CreateAlert';
import Login from './Views/Login';
import ListAlert from './Components/ListAlert';
import AppPopup from './Components/AppPopup';

import DefaultLayout from './Layouts/DefaultLayout';
import AuthLayout from './Layouts/AuthLayout';
import {
  Review,
  BookingRedirect,
  PrivacyPolicy,
  Terms,
  Landing,
  CreateAlertNew,
} from './Views';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4997a0',
      light: '#7bc8d1',
      dark: '#0a6972',
      contrastText: '#fff',
    },
    // background: {
    //   default: '#a7e7ef',
    // },
  },
  typography: {
    fontFamily: ['Montserrat', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
});

function App() {
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    history.listen((location, action) => {
      // location is an object like window.location
      // console.log(action, location.pathname, location.state);
      ReactGA.set({ page: location.pathname }); // Update the user's current page
    });
  }, [history]);

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Sentry.ErrorBoundary showDialog>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AppPopup />
          <Switch>
            <Route path="/" exact>
              <Landing />
            </Route>
            <Route path="/create-alert" exact>
              <DefaultLayout>
                <CreateAlert />
              </DefaultLayout>
            </Route>
            <Route path="/create-alert-new" exact>
              <DefaultLayout>
                <CreateAlertNew />
              </DefaultLayout>
            </Route>
            <Route path="/login">
              <AuthLayout>
                <Login />
              </AuthLayout>
            </Route>
            <Route path="/alerts">
              {(props) => (
                <CheckLogin {...props}>
                  <DefaultLayout>
                    <ListAlert />
                  </DefaultLayout>
                </CheckLogin>
              )}
            </Route>
            <Route
              path="/tip"
              component={() => {
                window.location.href =
                  'https://checkout.square.site/pay/9ddf0fcf7acb4217a4043ff1292de326';
                return null;
              }}
            />
            <Route path="/review">
              {(props) => (
                <CheckLogin {...props}>
                  <DefaultLayout>
                    <Review />
                  </DefaultLayout>
                </CheckLogin>
              )}
            </Route>
            <Route path="/booking/:id">
              <DefaultLayout>
                <BookingRedirect />
              </DefaultLayout>
            </Route>
            <Route path="/privacy-policy">
              <DefaultLayout>
                <PrivacyPolicy />
              </DefaultLayout>
            </Route>
            <Route path="/terms">
              <DefaultLayout>
                <Terms />
              </DefaultLayout>
            </Route>
            <Route path="/landing">
              <Landing />
            </Route>
          </Switch>
        </ThemeProvider>
      </StyledEngineProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
