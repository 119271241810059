import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// MUI
import { makeStyles } from '@mui/styles';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
}));

export const ListEmpty = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography color="textSecondary">You don&apos;t have any active alerts!</Typography>
      <Typography>
        <Link component={RouterLink} to="/" underline="hover">
          Create an alert
        </Link>{' '}
        to get started
      </Typography>
    </div>
  );
};

export default ListEmpty;
