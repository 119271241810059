import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { isLoggedIn } from '../../_redux/auth/selectors';

export const CheckLogin = props => {
  const {
    location: { pathname },
    children,
    loggedIn,
  } = props;

  if (loggedIn) return children;
  return <Redirect to={`/login?nextUrl=${pathname}`} />;
};
CheckLogin.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  loggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  loggedIn: isLoggedIn(state),
});

export default connect(mapStateToProps)(CheckLogin);
