import * as types from './types';
import { saveState } from '../localStorage';
import { fetchAlertPromo } from '../plans';
import callApi from '../../_utils/callApi';

const CLIENT_ID = '0bce65f0-bf47-41f7-8d47-aca06bc6051f';
const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const initiateSignIn = (
  phone: string,
): AppThunk<Promise<[StringKeyObject, boolean]>> => async () => {
  if (IS_PRODUCTION) phone = `+1${phone}`;

  try {
    const [response, error] = await callApi({
      endpoint: 'InitiateSignIn',
      method: 'POST',
      body: { client_id: CLIENT_ID, phone },
    });
    if (error) return [response, false];
    return [response, true];
  } catch (err) {
    return [{ message: String(err) }, false];
  }
};

export const completeSignIn = (
  phone: string,
  verificationCode: string,
): AppThunk<Promise<StringKeyObject | null>> => async dispatch => {
  if (IS_PRODUCTION) phone = `+1${phone}`;

  try {
    const [response, error] = await callApi({
      endpoint: 'completesignin',
      method: 'POST',
      body: {
        client_id: CLIENT_ID,
        phone,
        verification_code: verificationCode,
      },
    });
    if (error) return response;
    const { access_token: accessToken } = response;
    dispatch({
      type: types.RECEIVE_COMPLETE_SIGNIN,
      accessToken,
      phone,
    });
    dispatch(fetchAlertPromo(accessToken));
    saveState('accessToken', accessToken);
    return null;
  } catch (err) {
    return { message: String(err) };
  }
};

export const logout = (): AppThunk => dispatch => {
  saveState('accessToken', null);
  dispatch({
    type: types.LOGOUT,
  });
};

export const sendReview = (values: {
  From: string;
  Body: string;
  name: string;
}): AppThunk<Promise<null | StringKeyObject>> => async (dispatch, getStore) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [response, error] = await callApi({
      endpoint: 'textreview',
      method: 'POST',
      body: values,
    });
    // if (error) return response;
    return null;
  } catch (err) {
    return { message: String(err) };
  }
};
