import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  format,
  addDays,
  addMinutes,
  startOfWeek,
  formatDistanceToNow,
} from 'date-fns';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// MUI
import {
  CircularProgress,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Typography,
  Box,
  Divider,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

// Icons
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';

import {
  pauseAlert,
  reactiveExpiredAlert,
} from '../../../_redux/actions/alert';

import CalendarHeader from './CalendarHeader';
import CalendarItem from './CalendarItem';

const useStyles = makeStyles(theme =>
  createStyles({
    card: {
      // minWidth: 275,
      width: 335,
      margin: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    cardActions: {
      justifyContent: 'center',
    },
    statusContainer: {
      marginBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // flexGrow: 1,
    },
    availableText: {
      color: '#1abc9c',
    },
    chipContainer: {
      width: '100%',
      // maxWidth: 275,
      marginTop: theme.spacing(1),
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    chip: {
      margin: theme.spacing(0, 0.5, 0.5, 0),
    },
    availableChip: { color: '#1abc9c', borderColor: '#1abc9c' },
    calendarItemsRoot: {
      [theme.breakpoints.down('sm')]: { overflowX: 'scroll' },
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      paddingBottom: theme.spacing(1),
    },
    fontMedium: { fontWeight: theme.typography.fontWeightMedium },
  }),
);

interface ListItemProps {
  alert: StringKeyObject;
  setEditing: (id: string) => void;
  expired: boolean;
}

export const ListItem: React.FC<ListItemProps> = props => {
  const { alert, setEditing, expired } = props;
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  const [pauseLoading, setPauseLoading] = useState(false);

  const toggleAlertPause = useCallback(async () => {
    setPauseLoading(true);
    await dispatch(pauseAlert(alert._id, !alert.paused));
    setPauseLoading(false);
  }, [alert._id, alert.paused, dispatch]);

  const reactiveAlert = useCallback(async () => {
    await dispatch(
      reactiveExpiredAlert({
        value: alert.resourceId,
        label: alert.resourceName,
      }),
    );
    history.push('/');
  }, [alert.resourceId, alert.resourceName, dispatch, history]);

  const availability = useMemo(() => {
    if (!alert.status?.loops?.[0]?.dailycalendar?.startDate) return [];
    const startDate = addMinutes(
      new Date(alert.status.loops[0]?.dailycalendar.startDate),
      new Date().getTimezoneOffset(),
    );
    const endDate = addDays(startDate, 28);
    const weekStart = startOfWeek(startDate);

    const normalizedData = [];
    const loops = alert.status.loops.reduce((acc: any, crr: any) => {
      acc[crr.loopName] = [...crr.dailycalendar.availability];
      return acc;
    }, {});
    // console.log({ loops });

    // Empty data in array from start of week to start date
    for (var d = new Date(weekStart); d < startDate; d = addDays(d, 1)) {
      normalizedData.push({ date: new Date(d) });
    } // Data from start to end date
    for (d = startDate; d <= endDate; d = addDays(d, 1)) {
      const dailyLoops = Object.keys(loops).reduce<StringKeyObject>(
        (acc, crr) => {
          acc[crr] = loops[crr].shift();
          return acc;
        },
        {},
      );
      normalizedData.push({
        date: new Date(d),
        loops: dailyLoops,
      });
    }
    return normalizedData;
  }, [alert.status]);
  // console.log(availability);

  return (
    <Card className={classes.card} key={alert._id}>
      <CardContent className={classes.cardContent}>
        <Typography variant="body2" align="right" gutterBottom>
          {!!alert?.status?.lastChecked &&
            `Last checked ${formatDistanceToNow(
              new Date(alert.status.lastChecked),
              { addSuffix: true },
            )}`}
        </Typography>
        <Typography variant="body2" align="right" gutterBottom>
          {`Expires on ${format(new Date(alert.startDate), 'do MMM')}`}
        </Typography>
        <Divider />
        <Typography variant="h6" component="h2">
          {alert.resourceName}
        </Typography>
        <Typography color="textSecondary">
          Interval: {alert.interval} minutes
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          {alert.phoneNumber}
        </Typography>
        <Divider />
        <Box mt={1}>
          <Typography
            variant="body2"
            align="center"
            className={classes.fontMedium}
          >
            Your trip dates: {format(new Date(alert.startDate), 'MMM do')} -{' '}
            {format(new Date(alert.endDate), 'MMM do yy')}
          </Typography>
        </Box>
        {alert.status && (
          <div className={classes.statusContainer}>
            {alert.status.available ? (
              <Typography
                variant="body2"
                align="center"
                className={classes.availableText}
              >
                Available
              </Typography>
            ) : (
              <Typography variant="body2" color="error" align="center">
                Unavailable
              </Typography>
            )}
            {!!alert.status.loops.length && (
              <div className={classes.chipContainer}>
                {alert.status.loops.map(
                  (
                    {
                      loopName,
                      available,
                    }: { loopName: string; available: boolean },
                    i: number,
                  ) => (
                    <Chip
                      key={i}
                      size="small"
                      variant="outlined"
                      label={loopName}
                      color={available ? 'default' : 'secondary'}
                      icon={available ? <DoneIcon /> : <CancelIcon />}
                      className={clsx(classes.chip, {
                        [classes.availableChip]: available,
                      })}
                    />
                  ),
                )}
              </div>
            )}
          </div>
        )}
        <Box flexGrow={1} />
        {!expired && !!availability.length && (
          <>
            <Divider />
            <div className={classes.calendarItemsRoot}>
              <CalendarHeader />
              {availability.map(data => (
                <CalendarItem key={data.date.getTime()} data={data} />
              ))}
            </div>
          </>
        )}
      </CardContent>
      <CardActions className={classes.cardActions}>
        {expired ? (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={reactiveAlert}
            // disabled={expired}
          >
            Reactivate
          </Button>
        ) : (
          <>
            <Button
              size="small"
              variant={alert.paused ? 'contained' : 'outlined'}
              color="primary"
              onClick={toggleAlertPause}
              disabled={pauseLoading}
              startIcon={
                pauseLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  undefined
                )
              }
            >
              {alert.paused ? 'Resume' : 'Pause'}
            </Button>
          </>
        )}
      </CardActions>
    </Card>
  );
};

ListItem.propTypes = {
  alert: PropTypes.object.isRequired,
  setEditing: PropTypes.func.isRequired,
  expired: PropTypes.bool.isRequired,
};

export default React.memo(ListItem);
