import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  differenceInCalendarDays,
  isToday,
  isAfter,
  getMonth,
  getYear,
  addMinutes,
} from 'date-fns';

import {
  handleStartDateChange,
  handleEndDateChange,
  getCalendar,
  getMonthlyCalendar,
} from '../../_redux/actions/alert';
import { makeStatusForDateSelector } from '../../_redux/selectors/alert';
import { useTypedSelector } from '../../_redux/reducers';

import {
  Grid,
  Box,
  ThemeProvider,
  StyledEngineProvider,
  Typography,
} from '@mui/material';
import { createTheme, Theme } from '@mui/material/styles';
import { lightBlue } from '@mui/material/colors';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DatePicker,
  PickersDay,
  LocalizationProvider,
  PickersDayProps,
} from '@mui/x-date-pickers';

import { useDebouncedCallback } from '../../hooks';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const defaultMaterialTheme = (theme: Theme) =>
  createTheme({
    ...theme,
    palette: {
      primary: lightBlue,
    },
  });

export function ChooseDates() {
  const dispatch: AppDispatch = useDispatch();
  const startDate: Date = useTypedSelector((state) => state.alert.startDate);
  const endDate: Date = useTypedSelector((state) => state.alert.endDate);
  const city = useTypedSelector((state) => state.alert.city);

  useEffect(() => {
    dispatch(getCalendar(city.value));
  }, [city.value, dispatch]);

  const disableEndDates = (day: Date) => {
    if (!day) return true;
    const diffDays = differenceInCalendarDays(day, startDate);
    return diffDays < 1 || diffDays > 6;
  };

  const disableStartDates = (day: Date) => {
    if (!day) return true;
    if (isToday(day)) return true;
    if (getYear(day) >= 2025) return true;
    return false;
  };

  const [fetchMonthlyCalendar] = useDebouncedCallback(
    (resourceId: string, date: Date) => {
      dispatch(getMonthlyCalendar(resourceId, getMonth(date), getYear(date)));
    },
    500,
  );

  const handleMonthChange = (date: Date) => {
    if (!date) return;
    const octEnd = addMinutes(
      new Date('2020-10-30'),
      new Date('2020-10-30').getTimezoneOffset(),
    );
    if (isAfter(date, octEnd)) {
      fetchMonthlyCalendar(city.value, date);
      // dispatch(getMonthlyCalendar(city.value, getMonth(date), getYear(date)));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container justifyContent="space-around">
        <DatePicker
          disablePast
          views={['day']}
          shouldDisableDate={disableStartDates}
          onMonthChange={handleMonthChange}
          format="MM/dd/yyyy"
          label="Check in date"
          value={startDate}
          onChange={(date) => dispatch(handleStartDateChange(date))}
          slots={{ day: RenderDay }}
          showDaysOutsideCurrentMonth={true}
        />
        <DatePicker
          disablePast
          views={['day']}
          shouldDisableDate={disableEndDates}
          format="MM/dd/yyyy"
          label="Check out date"
          value={endDate}
          onChange={(date) => dispatch(handleEndDateChange(date))}
          slots={{ day: RenderDay }}
          showDaysOutsideCurrentMonth={true}
        />
      </Grid>
      <Box mt={4}>
        <Typography component="li" variant="caption" align="left">
          Green and red dots represent latest daily availability.
        </Typography>
        <Typography component="li" variant="caption" align="left">
          Shorter trips increase chances of finding cancellation.
        </Typography>
      </Box>
    </LocalizationProvider>
  );
}

export default React.memo(ChooseDates);

const RenderDay: React.FC<PickersDayProps<Date>> = (props) => {
  const city = useTypedSelector((state) => state.alert.city);
  const selectStatusForDay = useMemo(makeStatusForDateSelector, []);
  const status = useTypedSelector((state) =>
    selectStatusForDay(state, city.value, props.day),
  );

  const statusColor = useMemo(() => {
    if (status === 0) return 'green';
    if (status === 1) return 'red';
    return 'transparent';
  }, [status]);

  if (status === null) return <PickersDay {...props} />;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultMaterialTheme}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          <PickersDay {...props} />
          <Box
            position="absolute"
            bottom={4}
            borderRadius="2.5px"
            height={5}
            width={5}
            bgcolor={statusColor}
          />
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
