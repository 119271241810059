import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
}));

interface SummaryProps {
  handleReset: () => void;
}

const Summary: React.FC<SummaryProps> = (props) => {
  const classes = useStyles();
  const { handleReset } = props;
  const history = useHistory();

  useEffect(() => {
    const timeout = setTimeout(() => history.push('/alerts'), 3000);
    return () => clearTimeout(timeout);
  }, [history]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography align="center">
          Your Alert has been created succesfully
        </Typography>
        <Typography align="center">Redirecting to alerts list page</Typography>
      </Grid>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Button color="primary" onClick={handleReset}>
          Create New Alert
        </Button>
        <Button
          color="primary"
          variant="contained"
          to="/alerts"
          component={Link}
          className={classes.marginLeft}
        >
          View my Alerts
        </Button>
      </Grid>
    </Grid>
  );
};

export default React.memo(Summary);
