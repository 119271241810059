import React, { useCallback } from 'react';

// MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
} from '@mui/material';

interface InfoDialogProps {
  interval: number | false;
  handleClose: () => void;
  openPayment: (interval: number, skipCheck?: boolean) => void;
}

const InfoDialog: React.FC<InfoDialogProps> = props => {
  const { interval, handleClose, openPayment } = props;

  const handleOpenFiveMinPlan = useCallback(() => {
    handleClose();
    openPayment(5);
  }, [handleClose, openPayment]);

  const continueAnyway = useCallback(() => {
    if (!interval) return;
    handleClose();
    openPayment(interval, true);
  }, [handleClose, interval, openPayment]);

  return (
    <Dialog
      open={!!interval}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" align="center">
          We recommended 5 min plan for weekends &amp; popular campgrounds
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleOpenFiveMinPlan}
          color="primary"
          variant="contained"
        >
          Select 5 min Interval
        </Button>
        <Button onClick={continueAnyway} color="primary">
          Continue Anyway
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(InfoDialog);
