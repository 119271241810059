import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { setActiveStep } from '../../_redux/actions/stepper';

interface ErrorDialogProps {
  open: boolean;
  handleClose: () => void;
}

const ErrorDialog: React.FC<ErrorDialogProps> = props => {
  const { open, handleClose } = props;
  const dispatch: AppDispatch = useDispatch();

  const handleGoBack = useCallback(() => {
    handleClose();
    dispatch(setActiveStep(1));
  }, [dispatch, handleClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Error</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          color="error"
          align="center"
        >
          Sorry! 30 min plan only allows setting date in next 2 weeks. Please choose 5 min plan or change your start date
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGoBack} color="primary">
          Change start date
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ErrorDialog);
