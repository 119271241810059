import React, { useMemo } from 'react';

import {
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

interface SelectSubresourcesProps {
  subresources: StringKeyObject[];
}

const SelectSubresources: React.FC<SelectSubresourcesProps> = (props) => {
  const classes = useStyles();
  const { subresources } = props;

  const renderCheckbox = useMemo(
    () => (
      <FormControl
        component="fieldset"
        className={classes.formControl}
        // error={!selectedLoopsValid}
      >
        <FormLabel component="legend">Select Loops</FormLabel>
        <FormGroup row>
          {subresources.map((subresource) => (
            <FormControlLabel
              key={subresource.resourceId}
              control={
                <Checkbox
                  // checked={selectedLoops.includes(loop)}
                  // onChange={handleChange}
                  name={subresource.resourceName}
                  color="primary"
                />
              }
              label={subresource.resourceName}
            />
          ))}
        </FormGroup>
        {/* {!selectedLoopsValid && ( */}
        <FormHelperText>Please select at least one loop</FormHelperText>
        {/* )} */}
      </FormControl>
    ),
    [classes.formControl, subresources],
  );

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        {renderCheckbox}
      </Grid>
    </Grid>
  );
};

export default React.memo(SelectSubresources);
