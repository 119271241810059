import callApi from '../../_utils/callApi';
import * as types from './types';
import { selectAllPlans } from './selectors';

export const fetchAlertPromo = (accessToken?: string): AppThunk => async (
  dispatch,
  getStore,
) => {
  if (!accessToken) {
    const store = getStore();
    accessToken = store.auth.accessToken!;
  }
  if (!accessToken) return;

  const [res, error] = await callApi({
    endpoint: 'firstalertpromo',
    method: 'POST',
    token: accessToken,
  });
  if (error) return error;
  return dispatch<types.PlansActionType>({
    type: types.RECEIVE_PROMO_DATA,
    data: res,
  });
};

export const pingPlansPageLoad = (): AppThunk => async (dispatch, getStore) => {
  const store = getStore();
  const accessToken = store.auth.accessToken!;
  const plans = selectAllPlans(store);
  const [res, error] = await callApi({
    endpoint: 'ping',
    method: 'POST',
    token: accessToken,
    body: { input: plans },
  });
  return [res, error];
};
