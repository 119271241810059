import React from 'react';
import { animated, useSpring } from 'react-spring';

import { Paper, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { CallSplit } from '@mui/icons-material';

const AnimatedCallSplit = animated(CallSplit);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      padding: theme.spacing(2, 0),
    },
    paperRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(4),
      width: 500,
      maxWidth: '100vw',
    },
    icon: {
      fontSize: 48,
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: theme.spacing(2),
    },
  }),
);

const BookingRedirect: React.FC = () => {
  const classes = useStyles();

  const animProps = useSpring({
    from: { color: '#acdcfb' },
    to: async (next: (x: StringKeyObject) => Promise<void>) => {
      let toggle = '#acdcfb';
      // eslint-disable-next-line no-constant-condition
      while (true) {
        await next({ color: toggle });
        toggle = toggle === '#acdcfb' ? '#3faaec' : '#acdcfb';
      }
    },
  });

  return (
    <div className={classes.root}>
      <Paper className={classes.paperRoot}>
        <AnimatedCallSplit className={classes.icon} style={animProps} />
        <Typography variant="h5" align="center" className={classes.title}>
          Hang Tight
        </Typography>
        <Typography align="center">
          We are redirecting you to campsite booking page,
          <br /> this may take a few seconds
        </Typography>
      </Paper>
    </div>
  );
};

export default React.memo(BookingRedirect);
