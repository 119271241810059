import { jwtDecode } from 'jwt-decode';
import { Reducer } from 'redux';
import { produce } from 'immer';
import { addDays } from 'date-fns';

import * as types from './types';
import { loadState } from '../localStorage';

interface decodedJwt {
  aud: string;
  iss: 'https://cancellationmonitor.com/issuer';
  sub: string;
  phone_number: string;
  phone_number_verified: boolean;
  exp: number;
  iat: number;
}

const getLocalJwtToken = () => {
  const token = loadState('accessToken');
  if (!token) return null;
  const decoded = jwtDecode<decodedJwt>(token);
  const expired = decoded.exp < addDays(new Date(), 1).getTime() / 1000;
  // console.log(expired);
  if (expired) return null;
  return token;
};

const getLocalPhoneNumber = () => {
  const token = loadState('accessToken') || null;
  if (!token) return '';
  const decoded = jwtDecode<decodedJwt>(token);
  const expired = decoded.exp < addDays(new Date(), 1).getTime() / 1000;
  if (expired) return '';
  return decoded.phone_number;
};

interface AuthState {
  phone: string;
  accessToken: null | string;
}

const initialState: AuthState = {
  phone: getLocalPhoneNumber(),
  accessToken: getLocalJwtToken(),
};

const auth: Reducer<AuthState, types.AuthActions> = (
  state = initialState,
  action,
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.RECEIVE_COMPLETE_SIGNIN:
        draft.accessToken = action.accessToken;
        draft.phone = action.phone;
        break;
      case types.LOGOUT:
        return { accessToken: null, phone: '' };
    }
  });

export default auth;
