import React, { useCallback, useEffect, useMemo } from 'react';

import { connect, useDispatch } from 'react-redux';
import {
  handleNext,
  handleBack,
  handleReset,
} from '../../_redux/actions/stepper';
import { fetchAlertPromo } from '../../_redux/plans';

import {
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  Button,
  Paper,
  Typography,
  MobileStepper,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import ChooseDates from './ChooseDates';
import SelectCamp from './SelectCamp';
import EnterPhone from './EnterPhone';
import SelectPlan from './SelectPlan';
import Summary from './Summary';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
    },
    stepperContainer: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2),
      },
    },
    stepContentContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    stepperFooter: {
      display: 'flex',
      justifyContent: 'center',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    feedback: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

interface CreateAlertProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

export const CreateAlert: React.FC<CreateAlertProps> = (props) => {
  const {
    activeStep,
    errorMessage,
    datesValid,
    cityValid,
    handleNext,
    handleBack,
    handleReset,
    isLoggedIn,
  } = props;
  const dispatch: AppDispatch = useDispatch();
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const steps = useMemo(() => {
    return [
      {
        mobile:
          'Keep an eye on cancellations and get reservations at sold out campgrounds',
        default: 'Pick the campground',
      },
      {
        mobile: 'Choose check in and check out dates',
        default: 'Choose your dates',
      },
      {
        mobile: 'Where can we notify you?',
        default: 'Where can we notify you?',
      },
      {
        mobile: 'How often should we check for cancellations?',
        default: 'How often should we check for cancellations?',
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(fetchAlertPromo());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handleReset();
    };
  }, [handleReset]);

  const isStepValid = useCallback(() => {
    switch (activeStep) {
      case 0:
        return cityValid;
      case 1:
        return datesValid;
      case 2:
        // return true;
        return isLoggedIn;
      // case 3:
      //   return !!alertCreated;
      default:
        return false;
    }
  }, [activeStep, cityValid, datesValid, isLoggedIn]);

  const renderStepContent = useMemo(() => {
    switch (activeStep) {
      case 0:
        return <SelectCamp />;
      case 1:
        return <ChooseDates />;
      case 2:
        return <EnterPhone />;
      case 3:
        return <SelectPlan />;
      default:
        return <Summary />;
    }
  }, [activeStep]);

  const renderMobileStepper = (
    <MobileStepper
      steps={steps.length}
      position="static"
      variant="text"
      activeStep={activeStep}
      nextButton={
        <Button size="small" onClick={handleNext} disabled={!isStepValid()}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          <KeyboardArrowRight />
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          <KeyboardArrowLeft />
          Back
        </Button>
      }
    />
  );

  return (
    <div className={classes.root}>
      {!isMobile && (
        <Typography variant="h5" align="center">
          Keep an eye on cancellations and get reservations at sold out
          campgrounds
        </Typography>
      )}
      <Paper className={classes.stepperContainer} elevation={0}>
        {isMobile ? (
          <Typography variant="h6" align="center">
            {steps[activeStep]?.mobile}
          </Typography>
        ) : (
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label.default}>
                <StepLabel>{label.default}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        <div>
          <div className={classes.stepContentContainer}>
            {renderStepContent}
          </div>
          {!isMobile && activeStep < steps.length && (
            <div className={classes.stepperFooter}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={!isStepValid()}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          )}
          <div className={classes.feedback}>
            {errorMessage && (
              <Typography variant="overline" color="error">
                {errorMessage}
              </Typography>
            )}
          </div>
          {isMobile && activeStep < steps.length && renderMobileStepper}
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  activeStep: state.stepper.activeStep,
  errorMessage: state.alert.errorMessage,
  datesValid: state.alert.datesValid,
  cityValid: state.alert.cityValid,
  isLoggedIn: !!state.auth.accessToken,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  handleNext: () => dispatch(handleNext()),
  handleBack: () => dispatch(handleBack()),
  handleReset: () => dispatch(handleReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAlert);
