import React, { useCallback, useEffect, useState } from 'react';
import { format, differenceInCalendarDays, isWeekend } from 'date-fns';

import { connect, useDispatch } from 'react-redux';
import { createAlert } from '../../_redux/actions/alert';
import { pingPlansPageLoad } from '../../_redux/plans';

import PaymentDialog from '../Common/PaymentDialog';
import ErrorDialog from '../Common/ErrorDialog';
import InfoDialog from '../Common/InfoDialog';

import { Grid, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { selectAllPlans, selectPromo } from '../../_redux/plans/selectors';
import { Plan } from '../../_redux/plans/types';

import PlanItem from '../Common/PlanItem';

const useStyles = makeStyles((theme) =>
  createStyles({
    cardsContainer: {
      margin: theme.spacing(2, 0),
    },
    cardGridItem: {
      display: 'flex',
    },
  }),
);

interface SelectPlanProps {
  startDate: string;
  endDate: string;
  city: { label: string; value: string } | undefined;
  createAlert: (interval: number) => Promise<boolean>;
  tiers: Plan[];
  promo: { interval?: number; count?: number };
  errorMessage: string;
}

export const SelectPlan: React.FC<SelectPlanProps> = (props) => {
  const { startDate, endDate, city, createAlert, tiers, promo, errorMessage } =
    props;
  const isMobile = useMediaQuery('(max-width:600px)');
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(pingPlansPageLoad());
  }, [dispatch]);

  const [isSaving, setIsSaving] = useState(false);
  const [interval, setInterval] = useState(60);
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const openError = useCallback(() => setErrorOpen(true), []);
  const closeError = useCallback(() => setErrorOpen(false), []);
  const [infoOpen, setInfoOpen] = useState<number | false>(false);
  const openInfo = useCallback((interval: number) => setInfoOpen(interval), []);
  const closeInfo = useCallback(() => setInfoOpen(false), []);

  const preSubmitErrorCheck = useCallback(
    (interval: number) => {
      if (interval >= 30) {
        if (differenceInCalendarDays(new Date(startDate), Date.now()) > 15) {
          return false;
        }
      }
      return true;
    },
    [startDate],
  );
  const preSubmitInfoCheck = useCallback(
    (interval: number) => {
      if (interval >= 30) {
        if (isWeekend(new Date(startDate)) || isWeekend(new Date(endDate))) {
          return false;
        }
      }
      return true;
    },
    [endDate, startDate],
  );

  const onSubmit = useCallback(
    async (int: number) => {
      const finalInterval = int ?? interval;
      setIsSaving(true);
      const success = await createAlert(finalInterval);
      if (!success) setIsSaving(false);
      return success;
    },
    [createAlert, interval],
  );

  const handleOpen = useCallback(
    (interval: number, skipCheck: boolean = false) => {
      const errorCheckPassed = preSubmitErrorCheck(interval);
      if (!errorCheckPassed) return openError();
      if (!skipCheck) {
        const infoCheckPassed = preSubmitInfoCheck(interval);
        if (!infoCheckPassed) return openInfo(interval);
      }
      const tier = tiers.find((x) => x.interval === interval);
      if (!tier) return;
      if (tier.price === 0 || tier.promoPrice === 0) {
        onSubmit(interval);
      } else {
        setInterval(interval);
        setOpen(true);
      }
    },
    [
      onSubmit,
      openError,
      openInfo,
      preSubmitErrorCheck,
      preSubmitInfoCheck,
      tiers,
    ],
  );
  const handleClose = () => {
    setInterval(60);
    setOpen(false);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Typography variant="body1">
          <strong>Check in Date:</strong> {startDate}
        </Typography>
        <Typography variant="body1">
          <strong>Check out Date:</strong> {endDate}
        </Typography>
        <Typography variant="body1">
          <strong>Campground: </strong>
          {city?.label ?? 'Select Campground'}
        </Typography>
      </Grid>
      <Grid
        container
        spacing={isMobile ? 2 : 5}
        alignItems="stretch"
        justifyContent="center"
        className={classes.cardsContainer}
      >
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            md={4}
            sm={6}
            className={classes.cardGridItem}
          >
            <PlanItem
              plan={tier}
              promo={promo}
              disabled={isSaving}
              handleOpen={handleOpen}
            />
          </Grid>
        ))}
      </Grid>
      <PaymentDialog
        open={open}
        handleClose={handleClose}
        onSubmit={onSubmit}
        errorMessage={errorMessage}
        interval={interval}
      />
      <ErrorDialog open={errorOpen} handleClose={closeError} />
      <InfoDialog
        interval={infoOpen}
        handleClose={closeInfo}
        openPayment={handleOpen}
      />
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  startDate: format(state.alert.startDate, 'MM/dd/yyyy'),
  endDate: format(state.alert.endDate, 'MM/dd/yyyy'),
  city: state.alert.city,
  tiers: selectAllPlans(state),
  promo: selectPromo(state),
  errorMessage: state.alert.errorMessage,
});

const mapDispatchToProps = (dispatch: any) => ({
  createAlert: (interval: number) => dispatch(createAlert(interval)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectPlan);
