import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
// Stripe
import { Elements, StripeProvider } from 'react-stripe-elements';

import configureStore from './_redux/store/configureStore';

import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

const store = configureStore();

const trackingId =
  process.env.NODE_ENV === 'production' ? 'UA-171087906-1' : 'UA-123456789-1';
ReactGA.initialize(trackingId, {
  // debug: process.env.NODE_ENV === 'development',
  testMode: process.env.NODE_ENV === 'test',
});
// ReactGA.set({
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
// })

Sentry.init({
  dsn:
    process.env.NODE_ENV === 'production'
      ? 'https://3677f8c5db4f4dc081bf575e55109156@o144510.ingest.sentry.io/5348650'
      : '',
});

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
      <Elements>
        <Router>
          <App />
        </Router>
      </Elements>
    </StripeProvider>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
  module.hot.accept();
}
