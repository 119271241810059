import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Paper,
  TextField,
  Button,
  Typography,
  Box,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

import { useTypedSelector } from '../_redux/reducers';
import { sendReview } from '../_redux/auth';
import { getUserPhoneNumber } from '../_redux/auth/selectors';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
    },
    paperRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(4),
      width: 600,
      maxWidth: '100vw',
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: theme.spacing(2),
    },
  }),
);

const Review: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const phoneNumber = useTypedSelector(getUserPhoneNumber);

  const [reviewSent, setReviewSent] = useState(false);

  const form = useFormik({
    initialValues: { Body: '', name: '' },
    validationSchema: Yup.object().shape({
      Body: Yup.string().required('Please write a review'),
      name: Yup.string().notRequired(),
    }),
    onSubmit: async values => {
      // console.log(values);
      const error = await dispatch(
        sendReview({ From: phoneNumber, ...values }),
      );
      if (!error) {
        setTimeout(() => {
          history.push('/');
        }, 2000);
        return setReviewSent(true);
      }
      // TODO: handle Error
    },
  });

  const { isSubmitting, handleSubmit, getFieldProps, errors, touched } = form;
  const getError = useCallback(
    (field: keyof typeof form.values) => !!(errors[field] && touched[field]),
    [errors, form, touched],
  );
  const getHelperText = useCallback(
    (field: keyof typeof form.values) => touched[field] && errors[field],
    [errors, form, touched],
  );

  const renderForm = useMemo(
    () => (
      <form onSubmit={handleSubmit} style={{ width: '100%' }} noValidate>
        <Typography variant="h5" align="center" className={classes.title}>
          Please Leave a Review
        </Typography>
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          placeholder="Name (Optional)"
          autoComplete="name"
          error={getError('name')}
          helperText={getHelperText('name')}
          {...getFieldProps('name')}
        />
        <TextField
          required
          margin="normal"
          variant="outlined"
          fullWidth
          multiline
          rows={10}
          placeholder="Enter your Review"
          error={getError('Body')}
          helperText={getHelperText('Body')}
          {...getFieldProps('Body')}
        />
        <Box my={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </Box>
      </form>
    ),
    [
      classes.title,
      getError,
      getFieldProps,
      getHelperText,
      handleSubmit,
      isSubmitting,
    ],
  );
  const renderSuccess = useMemo(
    () => (
      <Typography variant="h5" align="center" className={classes.title}>
        Thanks for the review, redirecting back to home page.
      </Typography>
    ),
    [classes.title],
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paperRoot}>
        {reviewSent ? renderSuccess : renderForm}
      </Paper>
    </div>
  );
};

export default React.memo(Review);
