import React from 'react';
import clsx from 'clsx';
import { Box, Typography, Container, Grid, useMediaQuery } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

import bgImg from './assets/bg.webp';
import bonfireImg from './assets/bonfire.svg';
// import fishingImg from './assets/fishing.svg';
import permitImg from './assets/permit.svg';
import ticketsImg from './assets/tickets.svg';

import InfoCard from './InfoCard';
// import ExploreCard from './ExploreCard';
import SelectCamp from './SelectCamp';
import FAQItem from './FAQItem';
import SectionHeading from './SectionHeading';

import DefaultLayout from '../../Layouts/DefaultLayout';

const Landing: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <DefaultLayout
      useContainer={false}
      headerProps={{ transparent: true, position: 'absolute', elevation: 0 }}
    >
      <div className={classes.root}>
        {/* Banner */}
        <div className={clsx(classes.topBanner, classes.bannerBorderRadii)}>
          <div className={clsx(classes.overlay, classes.bannerBorderRadii)} />
          <div className={classes.bannerContent}>
            <Typography
              variant={isMobile ? 'h4' : 'h2'}
              align="center"
              className={classes.fontBold}
            >
              Camping begins
              <br />
              here
            </Typography>
            <Typography align="center">
              Search over 2000 campgrounds and public parks, and make
              <br />
              reservations even when they are sold out.
            </Typography>
            <Box mt={4} width="100%" display="flex" justifyContent="center">
              <SelectCamp />
            </Box>
          </div>
        </div>
        {/* Info Cards */}
        <div className={classes.infoCardsWrapper}>
          <div className={classes.infoCardsContainer}>
            {infoCardsData.map((info) => (
              <InfoCard
                key={info.title}
                title={info.title}
                subtitle={info.subtitle}
                image={info.image}
              />
            ))}
          </div>
        </div>
        {/* FAQ */}
        <SectionHeading title="Frequently Asked Questions" />
        <Container>
          <Grid container spacing={2}>
            {faqs.map((x) => (
              <FAQItem
                key={x.question}
                question={x.question}
                answer={x.answer}
              />
            ))}
          </Grid>
        </Container>
        {/* Explore Destinations & Activities */}
        {/* <SectionHeading title="Explore Destinations &amp; Activities" />
      <Container className={classes.exploreCardsContainer}>
        <ExploreCard />
        <ExploreCard />
      </Container> */}
      </div>
    </DefaultLayout>
  );
};

export default Landing;

const infoCardsData = [
  {
    title: 'Search at one place',
    subtitle:
      'Easily plan your perfect camping trip! Start by searching for your preferred campground or park by name, and then check the daily availability in the calendar to find the perfect date. With our user-friendly search tool, it\'s never been easier to find the perfect campsite and start your adventure!',
    image: bonfireImg,
  },
  {
    title: 'Stay up-to-date on cancellations!',
    subtitle:
      'Campsite reservations are often cancelled, so if a campground is sold out for your dates, set up an alert to be notified when it becomes available again. Don\'t miss out - sign up for alerts today!',
    image: permitImg,
  },
  {
    title: 'Flat fee of $2.5',
    subtitle:
      'Pay a one-time flat fee of $2.5 to create an alert on any campground, and then get free text notifications every time a campsite becomes available. Snag the perfect spot before anyone else - sign up for alerts now!',
    image: ticketsImg,
  },
];

const faqs = [
  {
    question: 'What are the fees for creating an alert?',
    answer:
      'There is a one-time flat fee to create an alert and keep track of cancellations. The fee is $1 if you want to check for cancellations every 30 minutes, or $2.5 if you want to check every 5 minutes. All text notifications sent after the alert are free.',
  },
  {
    question: 'Can I use the site just to check availability?',
    answer:
      'Yes, you can use our site to easily check the daily availability of different campgrounds, without having to visit multiple sites. Checking availability is free, and if a campground is not available for your desired dates, you can create a cancellation alert to be notified as soon as it becomes available again.',
  },
  {
    question: 'How can I increase my chances of finding a cancellation?',
    answer:
      'We recommend setting an alert with an interval of 5 minutes when planning a trip to a popular campground or for a weekend. This will increase your chances of being notified as soon as a campsite becomes available.',
  },
  {
    question: 'Where can I learn more about you?',
    answer: (
      <>
        Follow us on our{' '}
        <a
          href="https://www.facebook.com/campsitemonitor/"
          target="_blank"
          rel="noreferrer"
        >
          facebook page
        </a>{' '}
        to stay informed about new features and read our reviews. You can also
        message us there with any additional questions.
      </>
    ),
  },
];

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(5),
    },
    topBanner: {
      display: 'flex',
      flexDirection: 'column',
      backgroundImage: `url(${bgImg})`,
      backgroundPosition: 'bottom',
      backgroundColor: '#3B3735',
      minHeight: '90vh',
      position: 'relative',
      paddingTop: theme.spacing(8),
    },
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: '#3B3735',
      opacity: 0.5,
    },
    bannerBorderRadii: {
      borderRadius: '0 0 32px 32px',
      [theme.breakpoints.up('sm')]: {
        borderRadius: '0 0 100px 100px',
      },
      [theme.breakpoints.up('md')]: {
        borderRadius: '0 0 200px 200px',
      },
    },
    bannerContent: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      zIndex: 1,
      padding: theme.spacing(1),
    },
    fontBold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    infoCardsWrapper: {
      position: 'relative',
    },
    infoCardsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        marginTop: -50,
      },
    },
    exploreCardsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  }),
);
