import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addDays, differenceInCalendarDays } from 'date-fns';

import { Paper, Grid } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

import { useTypedSelector as useSelector } from '../../_redux/reducers';
import { isLoggedIn } from '../../_redux/auth/selectors';
import { createAlertNew } from '../../_redux/actions/alert';

import { AlertFormValues } from './data';

import SelectCamp from './SelectCamp';
import ChooseDates from './ChooseDates';
import SelectSubresources from './SelectSubresources';
import EnterPhone from './EnterPhone';
import SelectPlan from './SelectPlan';
import Summary from './Summary';

const today = Date.now();
const API_BASE = process.env.REACT_APP_API_BASE;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { margin: theme.spacing(2, 0) },
    paperRoot: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2),
      },
    },
  }),
);

const CreateAlert: React.FC = () => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();

  const loggedIn = useSelector(isLoggedIn);
  const [created, setCreated] = useState(false);
  const [nonFieldErr, setNonFieldErr] = useState('');
  const [subresources, setSubresources] = useState([]);

  const form = useFormik<AlertFormValues>({
    initialValues: {
      city: null,
      startDate: addDays(today, 1),
      endDate: undefined,
      interval: undefined,
    },
    validationSchema: Yup.object().shape({
      city: Yup.object().required(),
      startDate: Yup.date().required(),
      endDate: Yup.date()
        .required('Please select number of nights')
        .test('dateMin', 'Min 1 night', function (val) {
          const startDate = this.parent.startDate;
          const diffDays = differenceInCalendarDays(val!, startDate);
          if (diffDays < 1) {
            return false;
          }
          return true;
        })
        .test('dateMax', 'Max 6 nights allowed', function (val) {
          const startDate = this.parent.startDate;
          const diffDays = differenceInCalendarDays(val!, startDate);
          if (diffDays > 6) {
            return false;
          }
          return true;
        }),
    }),
    onSubmit: async (values) => {
      console.log(values);
      // return Promise.resolve();
      const error = await dispatch(
        createAlertNew({
          resourceId: values.city!.resourceId,
          startDate: values.startDate,
          endDate: values.endDate!,
          interval: values.interval,
        }),
      );
      if (!error) return setCreated(true);
      setNonFieldErr(error);
    },
  });
  const { values, resetForm } = form;

  useEffect(() => {
    if (!values.city) return;
    fetch(`${API_BASE}/findResources`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: '',
      },
      body: JSON.stringify({
        client_id: '0bce65f0-bf47-41f7-8d47-aca06bc6051f',
        resourceId: values.city.resourceId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setSubresources(res);
      });
  }, [values.city]);

  const handleReset = useCallback(() => {
    resetForm();
    setCreated(false);
  }, [resetForm]);

  const shouldRenderCampSelect = useMemo(() => !created, [created]);
  const shouldRenderDates = useMemo(
    () => shouldRenderCampSelect && values.city !== null,
    [shouldRenderCampSelect, values.city],
  );
  const shouldRenderSubresources = useMemo(
    () =>
      shouldRenderDates &&
      values.endDate &&
      values.startDate &&
      subresources.length,
    [shouldRenderDates, subresources.length, values.endDate, values.startDate],
  );
  const shouldRenderPhone = useMemo(
    () => shouldRenderDates && values.endDate && values.startDate,
    [shouldRenderDates, values.endDate, values.startDate],
  );
  const shouldRenderPlans = useMemo(
    () => shouldRenderPhone && loggedIn,
    [loggedIn, shouldRenderPhone],
  );

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <Paper className={classes.paperRoot}>
            {shouldRenderCampSelect && <SelectCamp form={form} />}
            {shouldRenderDates && <ChooseDates form={form} />}
            {shouldRenderSubresources && (
              <SelectSubresources subresources={subresources} />
            )}
            {shouldRenderPhone && <EnterPhone />}
            {shouldRenderPlans && (
              <SelectPlan form={form} errorMessage={nonFieldErr} />
            )}
            {created && <Summary handleReset={handleReset} />}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(CreateAlert);
