import React, { ReactNode } from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { makeStyles, createStyles } from '@mui/styles';

interface FAQItemProps {
  question: string;
  answer: ReactNode;
}

export const FAQItem: React.FC<FAQItemProps> = (props) => {
  const { answer, question } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6}>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            color="textSecondary"
            variant="body2"
            className={classes.answerText}
          >
            {answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default React.memo(FAQItem);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      // fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
    },
    answerText: {
      wordBreak: 'break-word',
    },
  }),
);
