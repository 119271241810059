import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

interface SectionHeadingProps {
  title: string;
}

const SectionHeading: React.FC<SectionHeadingProps> = (props) => {
  const { title } = props;
  const classes = useStyles();

  return (
    <Box py={2}>
      <Typography
        variant="h5"
        color="textPrimary"
        align="center"
        className={classes.fontBold}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default React.memo(SectionHeading);

const useStyles = makeStyles((theme) =>
  createStyles({
    fontBold: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
);
