import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// MUI
import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import Autocomplete from '@mui/lab/Autocomplete';
import { FilterOptionsState } from '@mui/base/useAutocomplete';
import { Search as SearchIcon } from '@mui/icons-material';

import { useTypedSelector } from '../../_redux/reducers';
import { handleCityChange } from '../../_redux/actions/alert';
import { setActiveStep } from '../../_redux/actions/stepper';

import { useDebouncedCallback } from '../../hooks';

const API_BASE = process.env.REACT_APP_API_BASE;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('sm')]: { width: 300 },
      [theme.breakpoints.up('md')]: { width: 400 },
      [theme.breakpoints.up('lg')]: { width: 500 },
    },
    searchIcon: {
      color: theme.palette.text.secondary,
      paddingLeft: theme.spacing(1),
    },
    inputBase: {
      // [theme.breakpoints.up('sm')]: { width: 300 },
      // [theme.breakpoints.up('md')]: { width: 400 },
      // [theme.breakpoints.up('lg')]: { width: 500 },
      borderRadius: 999,
      backgroundColor: '#fff',
    },
    hidden: { display: 'none' },
  }),
);

const filterOptions = (options: any[], state: FilterOptionsState<any>) =>
  options.filter(
    (opt) =>
      opt.resourceName
        ?.toLowerCase()
        .includes(state.inputValue.toLowerCase()) ||
      opt.parent_name?.toLowerCase().includes(state.inputValue.toLowerCase()),
  );

export function SelectCity() {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  const city = useTypedSelector((state) => state.alert.city);

  const [options, setOptions] = useState<StringKeyObject[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = React.useState('');

  const handleChange = useCallback(
    async (e: React.ChangeEvent<{}>, val: any) => {
      dispatch(handleCityChange(val));
      dispatch(setActiveStep(1));
      history.push('/create-alert');
    },
    [dispatch, history],
  );

  const [fetchSuggestion] = useDebouncedCallback((query: string) => {
    if (!query.trim()) return setOptions([]);

    setLoading(true);
    fetch(`${API_BASE}/searchResources`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: '',
      },
      body: JSON.stringify({
        client_id: '0bce65f0-bf47-41f7-8d47-aca06bc6051f',
        query,
      }),
    })
      .then((res) => res.json())
      .then((res: StringKeyObject[]) =>
        setOptions(
          res.map((x) => ({
            ...x,
            label: x.resourceName,
            value: x.resourceId,
            address: [x.parent_name || x.city, x.state]
              .filter(Boolean)
              .join(', '),
          })),
        ),
      )
      .finally(() => setLoading(false));
  }, 500);
  const handleTextFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = e.target.value;
      if (!value) return;
      setLoading(true);
      fetchSuggestion(value);
    },
    [fetchSuggestion],
  );

  return (
    <Autocomplete
      id="select-campground"
      filterOptions={filterOptions}
      options={options}
      loading={loading}
      value={city}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={(option) => option.label}
      disableClearable
      forcePopupIcon={false}
      noOptionsText="Did not match any Campground"
      fullWidth
      className={classes.root}
      classes={{ noOptions: clsx({ [classes.hidden]: !inputValue }) }}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option.label}
          <Typography component="span" variant="body2" color="textSecondary">
            &nbsp;- {option.address}
          </Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={handleTextFieldChange}
          // autoFocus
          // label="Select Campground"
          placeholder="Park or campground name"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            classes: { root: classes.inputBase },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <InputAdornment position="end">
                    <CircularProgress color="inherit" size={20} />
                  </InputAdornment>
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default SelectCity;
