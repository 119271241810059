import { format, addDays, isSameMonth, addMinutes } from 'date-fns';
import * as Sentry from '@sentry/react';
import { jwtDecode } from 'jwt-decode';

import callApi from '../../../_utils/callApi';
import * as types from './types';
import * as stepperTypes from '../stepper/types';
import { fetchAlertPromo } from '../../plans';
import { logout } from '../../auth';

const API_BASE = process.env.REACT_APP_API_BASE;

// Action Creators
export const handleStartDateChange = (date: Date | null) => ({
  type: types.CHANGE_START_DATE,
  date,
});

export const handleEndDateChange = (date: Date | null) => ({
  type: types.CHANGE_END_DATE,
  date,
});

export const handleCityChange = (value: StringKeyObject) => ({
  type: types.CHANGE_CITY,
  value,
});

export const setStripeToken = (token: StringKeyObject) => ({
  type: types.SET_STRIPE_TOKEN,
  stripeToken: token,
});

export const createAlert = (interval = 60): AppThunk => async (
  dispatch,
  getStore,
) => {
  const store = getStore();
  const { accessToken } = store.auth;
  if (!accessToken) {
    dispatch({
      type: types.ERROR_CREATE_ALERT,
      message: 'Please Sign In to continue',
    });
    return false;
  }

  const { startDate, endDate, city, stripeToken } = store.alert;
  if (!city) {
    dispatch({
      type: types.ERROR_CREATE_ALERT,
      message: 'Please Select a campground',
    });
    return false;
  }
  const body: types.AlertCreatePayload = {
    resourceId: city.value,
    resources: [city.value],
    start_date: format(startDate, 'yyyy-MM-dd'),
    end_date: format(endDate, 'yyyy-MM-dd'),
    interval,
  };
  if (stripeToken) body.token = stripeToken.id;
  // if (process.env.NODE_ENV === 'development') body.token = 'TallCappuccino';
  // console.log(body);

  dispatch({ type: types.REQUEST_CREATE_ALERT });

  const [response, error] = await callApi({
    endpoint: 'alert',
    method: 'POST',
    token: accessToken,
    body,
  });
  if (error) {
    dispatch({
      type: types.ERROR_CREATE_ALERT,
      message: response.error,
    });
    // record error
    Sentry.captureEvent({
      message: 'Error Creating Alert',
      extra: { body, response },
    });
    return false;
  }
  dispatch({
    type: types.RECEIVE_CREATE_ALERT,
    data: response,
  });
  dispatch(fetchAlertPromo());
  dispatch({ type: stepperTypes.HANDLE_NEXT });
  return true;
};

interface ICreateAlertArgs {
  startDate: Date;
  endDate: Date;
  resourceId: string;
  interval?: number;
}
export const createAlertNew = ({
  startDate,
  endDate,
  resourceId,
  interval = 60,
}: ICreateAlertArgs): AppThunk<Promise<string | null>> => async (
  dispatch,
  getStore,
) => {
  const store = getStore();
  const { accessToken } = store.auth;
  if (!accessToken) return 'Please Sign In to continue';

  const { stripeToken } = store.alert;
  const body: types.AlertCreatePayload = {
    resourceId: resourceId,
    resources: [resourceId],
    start_date: format(startDate, 'yyyy-MM-dd'),
    end_date: format(endDate, 'yyyy-MM-dd'),
    interval,
  };
  if (stripeToken) body.token = stripeToken.id;
  // if (process.env.NODE_ENV === 'development') body.token = 'TallCappuccino';
  // console.log(body);

  const [response, error] = await callApi({
    endpoint: 'alert',
    method: 'POST',
    token: accessToken,
    body,
  });
  if (error) {
    // record error
    Sentry.captureEvent({
      message: 'Error Creating Alert',
      extra: { body, response },
    });
    return response.error;
  }
  dispatch({
    type: types.RECEIVE_CREATE_ALERT,
    data: response,
  });
  dispatch(fetchAlertPromo());
  return null;
};

export const patchAlert = (id: string, interval: number): AppThunk => async (
  dispatch,
  getStore,
) => {
  const store = getStore();
  const { accessToken } = store.auth;
  if (!accessToken) {
    dispatch({
      type: types.ERROR_PATCH_ALERT,
      message: 'Please Sign In to continue',
    });
    return false;
  }

  const { stripeToken } = store.alert;
  const body: { _id: string; interval: number; token?: string } = {
    _id: id,
    interval,
  };
  if (stripeToken) body.token = stripeToken.id;

  dispatch({ type: types.REQUEST_PATCH_ALERT });

  try {
    const response = await fetch(`${API_BASE}/alert/`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: accessToken,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      const jsonResponse = await response.json();
      dispatch({
        type: types.RECEIVE_PATCH_ALERT,
        data: jsonResponse,
      });
      return true;
    } else if (response.status === 400) {
      const jsonResponse = await response.json();
      dispatch({
        type: types.ERROR_PATCH_ALERT,
        message: jsonResponse.error,
      });
      return false;
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    dispatch({
      type: types.ERROR_PATCH_ALERT,
      message: String(err),
    });
    return false;
  }
};

export const listAlerts = (): AppThunk => async (dispatch, getStore) => {
  const store = getStore();
  const { accessToken } = store.auth;
  if (!accessToken) {
    return dispatch({
      type: types.ERROR_LIST_ALERTS,
      message: 'Please Sign in',
    });
  }

  interface decodedJwt {
    aud: string;
    iss: 'https://cancellationmonitor.com/issuer';
    sub: string;
    phone_number: string;
    phone_number_verified: boolean;
    exp: number;
    iat: number;
  }
  const decoded = jwtDecode<decodedJwt>(accessToken);
  const expired = decoded.exp < addDays(new Date(), 1).getTime() / 1000;
  if (expired) return dispatch(logout());

  dispatch({ type: types.REQUEST_LIST_ALERTS });
  try {
    const [response, error] = await callApi({
      endpoint: 'alerts',
      token: accessToken,
    });
    if (error) {
      if (response.error === 'unauthorized') {
        dispatch(logout());
      }
      return dispatch({
        type: types.ERROR_LIST_ALERTS,
        message: response.error,
      });
    }
    return dispatch({ type: types.RECEIVE_LIST_ALERTS, data: response });
  } catch (err) {
    return dispatch({ type: types.ERROR_LIST_ALERTS, message: String(err) });
  }
};

export const pauseAlert = (_id: string, paused: boolean): AppThunk => async (
  dispatch,
  getStore,
) => {
  const store = getStore();
  const { accessToken } = store.auth;
  if (!accessToken) {
    dispatch({
      type: types.ERROR_PATCH_ALERT,
      message: 'Please Sign In to continue',
    });
    return false;
  }

  try {
    const response = await fetch(`${API_BASE}/alert/`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: accessToken,
      },
      body: JSON.stringify({ _id, paused }),
    });
    if (response.status === 200) {
      const jsonResponse = await response.json();
      dispatch({
        type: types.RECEIVE_PATCH_ALERT,
        data: jsonResponse,
      });
      return true;
    } else if (response.status === 400) {
      const jsonResponse = await response.json();
      dispatch({
        type: types.ERROR_PATCH_ALERT,
        message: jsonResponse.error,
      });
      return false;
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    dispatch({
      type: types.ERROR_PATCH_ALERT,
      message: String(err),
    });
    return false;
  }
};

export const getCalendar = (resourceId: string): AppThunk => async (
  dispatch,
  getStore,
) => {
  const body = {
    resourceId,
    client_id: '0bce65f0-bf47-41f7-8d47-aca06bc6051f',
  };

  try {
    const response = await fetch(`${API_BASE}/calendar`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Api-Version': '1',
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      const jsonResponse = await response.json();
      dispatch({
        type: types.RECEIVE_CALENDAR,
        resourceId,
        data: jsonResponse,
      });
      return null;
    } else if (response.status === 400) {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    return err;
  }
};

export const getMonthlyCalendar = (
  resourceId: string,
  month: number,
  year: number,
): AppThunk => async (dispatch, getStore) => {
  const store = getStore();
  const { accessToken } = store.auth;
  const { monthlyCalendar } = store.alert;

  const monthString = `${year}-${(month + 1).toString().padStart(2, '0')}-01`;
  const alreadyFetched = Object.keys(monthlyCalendar[resourceId]).some(x =>
    isSameMonth(
      new Date(x),
      addMinutes(
        new Date(monthString),
        new Date(monthString).getTimezoneOffset(),
      ),
    ),
  );
  if (alreadyFetched) return;

  const [response, error] = await callApi({
    endpoint: 'monthlycalendar',
    method: 'POST',
    token: accessToken ?? undefined,
    body: {
      client_id: '0bce65f0-bf47-41f7-8d47-aca06bc6051f',
      resourceId,
      month,
      year,
    },
  });
  // console.log(response, error);
  if (error) return error;
  dispatch({
    type: types.RECEIVE_MONTHLY_CALENDAR,
    resourceId,
    month: `${year}-${month}`,
    data: response,
  });
};

export const reactiveExpiredAlert = (
  city: Partial<types.City>,
): AppThunk => async dispatch => {
  dispatch(handleCityChange(city));
  dispatch({ type: stepperTypes.SET_ACTIVE_STEP, step: 1 });
};
