export const RECEIVE_PROMO_DATA = 'RECEIVE_PROMO_DATA';

export interface Plan {
  interval: number;
  price: number;
  promoPrice: number;
  title: string;
  description: string[];
  buttonVariant: string;
  icon: string;
}

export interface Promo {
  interval?: number;
  count?: number;
  firstAlertInterval?: number;
  isFirstAlertPromoEnabled?: boolean;
  isOtherPromoEnabled?: boolean;
  otherPromoPrices?: { interval: number; promopriceincents: number }[];
}

// Actions
interface ReceivePromoDataAction {
  type: typeof RECEIVE_PROMO_DATA;
  data: Promo;
}

export type PlansActionType = ReceivePromoDataAction;
