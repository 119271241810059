import { Action, AnyAction, combineReducers } from 'redux';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import stepper from './stepper';
import alert from './alert';
import auth from '../auth/reducer';
import plans from '../plans/reducer';

const rootReducer = combineReducers({ alert, stepper, auth, plans });
export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
