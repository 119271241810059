import {
  differenceInCalendarDays,
  addDays,
  addMinutes,
  isAfter,
  format,
} from 'date-fns';
import * as types from '../actions/alert/types';
import * as authTypes from '../auth/types';
import * as stepperTypes from '../actions/stepper/types';

const today = Date.now();

const initialState = {
  startDate: addDays(today, 1),
  endDate: addDays(today, 2),
  datesValid: true,
  city: null,
  cityValid: false,
  errorMessage: '',
  stripeToken: null,
  alerts: [],
  calendar: {},
  monthlyCalendar: {},
};

const alert = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_START_DATE:
      return changeStartDate(state, action);
    case types.CHANGE_END_DATE:
      return changeEndDate(state, action);
    case types.CHANGE_CITY:
      return changeCity(state, action);
    case types.SET_STRIPE_TOKEN:
      return {
        ...state,
        stripeToken: action.stripeToken,
      };
    case types.REQUEST_CREATE_ALERT:
      return {
        ...state,
        errorMessage: '',
      };
    case types.RECEIVE_CREATE_ALERT:
      return receiveCreateAlert(state, action);
    case types.ERROR_CREATE_ALERT:
      return {
        ...state,
        errorMessage: action.message,
      };
    case types.REQUEST_PATCH_ALERT:
    case types.ERROR_PATCH_ALERT:
      return state;
    case types.RECEIVE_PATCH_ALERT:
      return receivePatchAlert(state, action);
    case types.REQUEST_LIST_ALERTS:
    case types.ERROR_LIST_ALERTS:
      return state;
    case types.RECEIVE_LIST_ALERTS:
      return { ...state, alerts: action.data };
    case types.RECEIVE_CALENDAR: {
      const finalData = {};
      const startDate = addMinutes(
        new Date(action.data.startDate),
        new Date(action.data.startDate).getTimezoneOffset(),
      );
      const endDate = addMinutes(
        new Date(action.data.endDate),
        new Date(action.data.endDate).getTimezoneOffset(),
      );
      const dailycalendar = [...action.data.dailycalendar];
      for (let d = startDate; !isAfter(d, endDate); d = addDays(d, 1)) {
        finalData[format(d, 'yyyy-MM-dd')] = dailycalendar.shift();
      }
      return {
        ...state,
        calendar: { ...state.calendar, [action.resourceId]: action.data },
        monthlyCalendar: {
          ...state.monthlyCalendar,
          [action.resourceId]: {
            ...state.monthlyCalendar[action.resourceId],
            ...finalData,
          },
        },
      };
    }
    case types.RECEIVE_MONTHLY_CALENDAR: {
      const finalData = {};
      const startDate = addMinutes(
        new Date(action.data.startDate),
        new Date(action.data.startDate).getTimezoneOffset(),
      );
      const endDate = addMinutes(
        new Date(action.data.endDate),
        new Date(action.data.endDate).getTimezoneOffset(),
      );
      const dailycalendar = [...action.data.dailycalendar];
      for (let d = startDate; !isAfter(d, endDate); d = addDays(d, 1)) {
        finalData[format(d, 'yyyy-MM-dd')] = dailycalendar.shift();
      }
      return {
        ...state,
        monthlyCalendar: {
          ...state.monthlyCalendar,
          [action.resourceId]: {
            ...state.monthlyCalendar[action.resourceId],
            ...finalData,
          },
        },
      };
    }
    case stepperTypes.HANDLE_RESET:
      return initialState;
    case authTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default alert;

const validateDates = (startDate, endDate) => {
  const diffDays = differenceInCalendarDays(endDate, startDate);
  if (diffDays < 1) {
    return [false, 'End Date must be less than or equal to Start Date'];
  }
  if (diffDays > 6) {
    return [false, 'Max 6 nights allowed'];
  }
  return [true, ''];
};

const changeStartDate = (state, action) => {
  let [datesValid, errorMessage] = validateDates(action.date, state.endDate);
  let endDate = new Date(state.endDate);
  /* Change end date to keep it valid */
  if (!datesValid) {
    endDate = addDays(new Date(action.date), 1);
    // endDate.setDate(action.date.getDate() + 2);
    [datesValid, errorMessage] = validateDates(action.date, endDate);
  }

  return {
    ...state,
    startDate: action.date,
    endDate,
    datesValid,
    errorMessage,
  };
};

const changeEndDate = (state, action) => {
  const [datesValid, errorMessage] = validateDates(
    state.startDate,
    action.date,
  );
  return {
    ...state,
    endDate: action.date,
    datesValid,
    errorMessage,
  };
};

const validateCity = city => {
  if (!city) {
    return [false, 'Select a Campground'];
  }
  return [true, ''];
};

const changeCity = (state, action) => {
  const [cityValid, errorMessage] = validateCity(action.value);
  return {
    ...state,
    city: action.value,
    cityValid,
    errorMessage,
  };
};

const receiveCreateAlert = (state, action) => {
  return {
    ...state,
    // alerts: [action.data, ...state.alerts],
  };
};

const receivePatchAlert = (state, action) => {
  const index = state.alerts.findIndex(x => x._id === action.data._id);
  return {
    ...state,
    alerts: [
      ...state.alerts.slice(0, index),
      action.data,
      ...state.alerts.slice(index + 1),
    ],
  };
};
