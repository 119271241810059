import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';

import {
  useMediaQuery,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Button,
  MenuItem,
  Menu,
  Box,
  AppBarProps,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useTypedSelector } from '../_redux/reducers';
import { logout } from '../_redux/auth';
import { isLoggedIn as getIsLoggedIn } from '../_redux/auth/selectors';

import logo from '../assets/white_logo192.png';

const useStyles = makeStyles((theme) => ({
  rootTransparent: {
    background: 'transparent',
  },
  logo: {
    height: theme.spacing(6),
    padding: theme.spacing(1),
  },
  title: {
    textDecoration: 'none',
    color: 'white',
  },
  grow: {
    flexGrow: 1,
  },
  rightContainer: {
    display: 'flex',
  },
  navLink: {
    height: '100%',
    borderRadius: 0,
    '& .activeIndicator': {
      position: 'absolute',
      width: '100%',
      height: 3,
      backgroundColor: 'currentColor',
      bottom: 0,
      display: 'none',
    },
  },
  navLinkActive: {
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none',
    '& .activeIndicator': {
      display: 'inline',
    },
  },
  mobNavLinkActive: { fontWeight: theme.typography.fontWeightMedium },
}));

export interface HeaderProps extends AppBarProps {
  transparent?: boolean;
}
const Header: React.FC<HeaderProps> = (props) => {
  const { transparent = false, className, ...otherProps } = props;
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const isLoggedIn = useTypedSelector(getIsLoggedIn);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const buttons = useMemo(
    () =>
      [
        {
          label: 'Create Alert',
          component: NavLink,
          to: '/create-alert',
          display: true,
        },
        {
          label: 'My Alerts',
          component: NavLink,
          to: '/alerts',
          display: isLoggedIn,
        },
        {
          label: 'Logout',
          onClick: () => dispatch(logout()),
          display: isLoggedIn,
        },
        {
          label: 'Login',
          component: NavLink,
          to: '/login',
          display: !isLoggedIn,
        },
      ].filter((x) => !!x.display),
    [dispatch, isLoggedIn],
  );
  const history = useHistory();

  useEffect(() => {
    history.listen(handleClose);
  }, [history, handleClose]);

  const rootClass = useMemo(
    () => clsx(className, { [classes.rootTransparent]: transparent }),
    [className, classes.rootTransparent, transparent],
  );

  return (
    <AppBar
      position="static"
      elevation={1}
      className={rootClass}
      {...otherProps}
    >
      <Toolbar style={{ alignItems: 'unset' }}>
        <Link to="/">
          <img className={classes.logo} src={logo} alt="Logo" />
        </Link>
        <Box display="flex" alignItems="center">
          <Typography
            component={Link}
            to="/"
            variant="h6"
            className={classes.title}
          >
            CampsiteMonitor.com
          </Typography>
        </Box>
        <div className={classes.grow} />
        {isMobile ? (
          <>
            <IconButton color="inherit" onClick={handleOpen} size="large">
              <MenuIcon />
            </IconButton>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {buttons.map((btn) => (
                <MenuItem
                  key={btn.label}
                  // color="inherit"
                  onClick={btn.onClick}
                  //@ts-ignore
                  component={btn.component}
                  to={btn.to}
                  exact
                  activeClassName={classes.mobNavLinkActive}
                >
                  {btn.label}
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          <div className={classes.rightContainer}>
            {buttons.map((btn) => (
              <Box mx={0.5} key={btn.label}>
                <Button
                  color="inherit"
                  onClick={btn.onClick}
                  //@ts-ignore
                  component={btn.component}
                  to={btn.to}
                  className={classes.navLink}
                  activeClassName={btn.to ? classes.navLinkActive : undefined}
                  exact
                >
                  {btn.label}
                  <span className="activeIndicator" />
                </Button>
              </Box>
            ))}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(Header);
