import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Stripe
import { CardElement, injectStripe } from 'react-stripe-elements';

// MUI
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { useTypedSelector as useSelector } from '../../_redux/reducers';
import { setStripeToken } from '../../_redux/actions/alert';
import { selectAllPlans } from '../../_redux/plans/selectors';

export function PaymentDialog(props) {
  const {
    open,
    handleClose,
    onSubmit,
    stripe,
    setStripeToken,
    errorMessage,
    interval,
  } = props;
  const tiers = useSelector(selectAllPlans);

  const [isSaving, setIsSaving] = useState(false);

  const submit = async e => {
    setIsSaving(true);
    const { token } = await stripe.createToken({ name: 'Name' });
    if (!token) return setIsSaving(false);
    await setStripeToken(token);
    const success = await onSubmit();
    if (!success) setIsSaving(false);
  };

  const price = useMemo(() => {
    const tier = tiers.find(x => x.interval === interval);
    return tier?.promoPrice ?? '';
  }, [interval, tiers]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Payment</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography>
            <strong>Interval:</strong> {interval} min
          </Typography>
          <Typography>
            <strong>Price:</strong> ${price}
          </Typography>
        </Box>
        <DialogContentText id="alert-dialog-description">
          Enter your Card Details to proceed with Payment via Stripe
        </DialogContentText>
        <Box my={3}>
          <CardElement />
        </Box>
        <Box mt={2} display="flex" justifyContent="center">
          {errorMessage && (
            <Typography variant="overline" color="error" align="center">
              {errorMessage}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isSaving}>
          Cancel
        </Button>
        <Button
          onClick={submit}
          color="primary"
          autoFocus
          disabled={isSaving}
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PaymentDialog.propTypes = {
  // Manual
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  // Auto
  stripe: PropTypes.object.isRequired,
  // Redux Actions
  setStripeToken: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setStripeToken: token => dispatch(setStripeToken(token)),
});

export default injectStripe(connect(null, mapDispatchToProps)(PaymentDialog));
