export const RECEIVE_COMPLETE_SIGNIN = 'RECEIVE_COMPLETE_SIGNIN';
export const LOGOUT = 'LOGOUT';

interface ReceiveCompleteSigninAction {
  type: typeof RECEIVE_COMPLETE_SIGNIN;
  accessToken: string;
  phone: string;
}

interface LogoutAction {
  type: typeof LOGOUT;
}

export type AuthActions = ReceiveCompleteSigninAction | LogoutAction;
