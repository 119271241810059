import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { patchAlert } from '../../_redux/actions/alert';

import PaymentDialog from '../Common/PaymentDialog';

import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { selectAllPlans } from '../../_redux/plans/selectors';

import PlanItem from '../Common/PlanItem';

const useStyles = makeStyles(theme => ({
  cardsContainer: {
    paddingBottom: theme.spacing(2.5),
  },
  cardGridItem: {
    display: 'flex',
  },
}));

export function UpgradeDialog(props) {
  const {
    open,
    handleClose,
    alertId,
    alert: { interval: currentInterval },
    patchAlert,
    plans,
  } = props;
  const classes = useStyles();

  const [isSaving, setIsSaving] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(null);

  const tiers = useMemo(() => plans.filter(x => x.interval < currentInterval), [
    currentInterval,
    plans,
  ]);

  const onSubmit = async () => {
    setIsSaving(true);
    const success = await patchAlert(alertId, paymentOpen);
    setIsSaving(false);
    if (success) {
      setPaymentOpen(null);
      handleClose();
    }
    return success;
  };

  if (!alertId) return null;
  if (tiers.length === 0)
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Already on the Best Plan</DialogTitle>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleClose}
            disabled={isSaving}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      <DialogContent className={classes.cardsContainer}>
        <Grid container spacing={2} alignItems="stretch" justifyContent="center">
          {tiers.map(tier => (
            <Grid
              item
              key={tier.title}
              xs={12}
              md={4}
              sm={6}
              className={classes.cardGridItem}
            >
              <PlanItem
                mode="upgrade"
                plan={tier}
                disabled={isSaving}
                handleOpen={setPaymentOpen}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <PaymentDialog
        open={!!paymentOpen}
        handleClose={() => setPaymentOpen(null)}
        onSubmit={onSubmit}
      />
    </Dialog>
  );
}

UpgradeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  alertId: PropTypes.string,
  alert: PropTypes.object.isRequired,
  patchAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { alertId } = ownProps;
  return {
    alert: state.alert.alerts.find(x => x._id === alertId) || {},
    plans: selectAllPlans(state),
  };
};

const mapDispatchToProps = dispatch => ({
  patchAlert: (...args) => dispatch(patchAlert(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeDialog);
