import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  differenceInCalendarDays,
  addMinutes,
  isToday,
  format,
  addDays,
  isSameDay,
  getYear,
} from 'date-fns';
import { FormikProps } from 'formik';

import {
  Box,
  ThemeProvider,
  StyledEngineProvider,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { createTheme, Theme } from '@mui/material';
import { lightBlue } from '@mui/material/colors';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  StaticDatePicker,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers';

import { useTypedSelector as useSelector } from '../../_redux/reducers';
import { getCalendar } from '../../_redux/actions/alert';
import { makeCalendarForResourceSelector } from '../../_redux/selectors/alert';

import { AlertFormValues } from './data';

const defaultMaterialTheme = (theme: Theme) =>
  createTheme({
    ...theme,
    palette: {
      primary: lightBlue,
    },
  });

interface ChooseDatesProps {
  form: FormikProps<AlertFormValues>;
}
const ChooseDates: React.FC<ChooseDatesProps> = (props) => {
  const { form } = props;
  const {
    values: { city, startDate, endDate },
    setFieldValue,
    errors,
  } = form;

  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  const handleStartDateChange = useCallback(
    (date: Date | null) => {
      setFieldValue('startDate', date);
      setFieldValue('endDate', undefined);
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (city) dispatch(getCalendar(city.value));
  }, [city, dispatch]);

  const disableStartDates = (day: Date) => {
    if (!day) return true;
    if (isToday(day)) return true;
    if (getYear(day) >= 2025) return true;
    return false;
  };

  return (
    <div className={classes.root} ref={ref}>
      <Typography variant="h6" align="center" gutterBottom>
        Choose check in date
      </Typography>
      <Box display="flex" justifyContent="center">
        {/* Start Date */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            disablePast
            views={['day']}
            shouldDisableDate={disableStartDates}
            value={startDate}
            onChange={handleStartDateChange}
            slots={{
              day: (props) => <RenderDay resourceId={city?.value} {...props} />,
            }}
          />
        </LocalizationProvider>
      </Box>
      <Box my={2}>
        <Typography component="li" variant="caption" align="center">
          Green and red dots represent latest daily availability.
        </Typography>
        <Typography component="li" variant="caption" align="center">
          Shorter trips increase chances of finding cancellation.
        </Typography>
      </Box>
      <Typography variant="h6" align="center" gutterBottom>
        Choose number of nights
      </Typography>
      <Box display="flex" justifyContent="center">
        <List style={{ width: '100%', maxWidth: 360 }}>
          {[1, 2, 3, 4, 5, 6].map((value) => {
            const date = addDays(startDate, value);
            const isChecked = !!endDate && isSameDay(date, endDate);
            const labelId = `${value}-nights`;

            return (
              <ListItem
                key={value}
                role={undefined}
                dense
                button
                onClick={() => setFieldValue('endDate', date)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={isChecked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={`${value} Nights`}
                  secondary={format(date, 'MM/dd/yyyy')}
                />
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Typography align="center" color="error">
        {errors.endDate}
      </Typography>
    </div>
  );
};

export default React.memo(ChooseDates);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2, 0),
    },
  }),
);

interface RenderDayProps extends PickersDayProps<Date> {
  resourceId?: string;
}

const RenderDay: React.FC<RenderDayProps> = (props) => {
  const { resourceId, ...rest } = props;
  const selectCalendar = useMemo(makeCalendarForResourceSelector, []);
  const calendar = useSelector((state) =>
    selectCalendar(state, resourceId ?? ''),
  );
  const getStatusForDate = useCallback(
    (day: Date) => {
      if (!(day && calendar && calendar.dailycalendar)) return;
      const startDate = addMinutes(
        new Date(calendar.startDate),
        new Date().getTimezoneOffset(),
      );
      const index = differenceInCalendarDays(day, startDate);
      return calendar.dailycalendar[index];
    },
    [calendar],
  );

  const status = getStatusForDate(props.day);

  const statusColor = useMemo(() => {
    if (status === 0) return 'green';
    if (status === 1) return 'red';
    return 'transparent';
  }, [status]);

  if (status === null) return <PickersDay {...rest} />;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultMaterialTheme}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          <PickersDay {...rest} />
          <Box
            position="absolute"
            bottom={4}
            borderRadius="2.5px"
            height={5}
            width={5}
            bgcolor={statusColor}
          />
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
