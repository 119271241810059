import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useSpring, animated } from 'react-spring';

interface InfoCardProps {
  title: string;
  subtitle: string;
  image: string;
}

export const InfoCard: React.FC<InfoCardProps> = (props) => {
  const { title, subtitle, image } = props;
  const classes = useStyles();

  const [animProps, set] = useSpring(() => ({
    s: 1,
    config: { mass: 1, tension: 350, friction: 10 },
  }));

  const onMouseMove = useCallback(() => set({ s: 1.02 }), [set]);
  const onMouseLeave = useCallback(() => set({ s: 1 }), [set]);

  return (
    <animated.div
      className={classes.root}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      style={{ transform: animProps.s.to(trans) }}
    >
      <img src={image} alt="" />
      <Box my={1.5}>
        <Typography
          color="textPrimary"
          align="center"
          className={classes.fontBold}
        >
          {title}
        </Typography>
      </Box>
      <Typography variant="body2" color="textPrimary" align="center">
        {subtitle}
      </Typography>
    </animated.div>
  );
};

export default React.memo(InfoCard);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      padding: theme.spacing(5, 3),
      background: '#FFFFFF',
      borderRadius: 20,
      // flex: '1 1 0',
      width: 220,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    fontBold: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
);

const trans = (s: number) => `scale(${s})`;
