import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import { FormikProps } from 'formik';

// MUI
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import Autocomplete from '@mui/lab/Autocomplete';
import { FilterOptionsState } from '@mui/base/useAutocomplete';
import { Search as SearchIcon } from '@mui/icons-material';

import { useDebouncedCallback } from '../../hooks';
import { AlertFormValues, City } from './data';

const API_BASE = process.env.REACT_APP_API_BASE;

interface SelectCampProps {
  form: FormikProps<AlertFormValues>;
}
const SelectCamp: React.FC<SelectCampProps> = (props) => {
  const { form } = props;
  const classes = useStyles();

  const [options, setOptions] = useState<StringKeyObject[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = React.useState('');

  const handleChange = useCallback(
    async (e: React.ChangeEvent<{}>, val: City) => {
      form.setFieldValue('city', val);
    },
    [form],
  );

  const [fetchSuggestion] = useDebouncedCallback((query: string) => {
    if (!query.trim()) return setOptions([]);

    setLoading(true);
    fetch(`${API_BASE}/searchResources`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: '',
      },
      body: JSON.stringify({
        client_id: '0bce65f0-bf47-41f7-8d47-aca06bc6051f',
        query,
      }),
    })
      .then((res) => res.json())
      .then((res: StringKeyObject[]) =>
        setOptions(
          res.map((x) => ({
            ...x,
            label: x.resourceName,
            value: x.resourceId,
            address: [x.parent_name || x.city, x.state]
              .filter(Boolean)
              .join(', '),
          })),
        ),
      )
      .finally(() => setLoading(false));
  }, 500);
  const handleTextFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = e.target.value;
      if (!value) return;
      setLoading(true);
      fetchSuggestion(value);
    },
    [fetchSuggestion],
  );

  return (
    <Grid container justifyContent="space-around">
      <Typography variant="h6" align="center" gutterBottom>
        Pick the campground
      </Typography>
      <Grid item xs={12}>
        <Autocomplete
          id="select-campground"
          filterOptions={filterOptions}
          options={options}
          loading={loading}
          value={form.values.city}
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={(option) => option.label}
          disableClearable
          forcePopupIcon={false}
          noOptionsText="Did not match any Campground"
          classes={{ noOptions: clsx({ [classes.hidden]: !inputValue }) }}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.label}
              <Typography
                component="span"
                variant="body2"
                color="textSecondary"
              >
                &nbsp;- {option.address}
              </Typography>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={handleTextFieldChange}
              autoFocus
              placeholder="Park or campground name"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                classes: { root: classes.inputBase },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <InputAdornment position="end">
                        <CircularProgress color="inherit" size={20} />
                      </InputAdornment>
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(SelectCamp);

const useStyles = makeStyles((theme) =>
  createStyles({
    searchIcon: {
      color: theme.palette.text.secondary,
      paddingLeft: theme.spacing(1),
    },
    inputBase: {
      borderRadius: 999,
    },
    hidden: { display: 'none' },
  }),
);

const filterOptions = (options: any[], state: FilterOptionsState<any>) =>
  options.filter(
    (opt) =>
      opt.resourceName
        ?.toLowerCase()
        .includes(state.inputValue.toLowerCase()) ||
      opt.parent_name?.toLowerCase().includes(state.inputValue.toLowerCase()),
  );
