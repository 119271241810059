import { createSelector } from 'reselect';
import { format } from 'date-fns';

export const makeCalendarForResourceSelector = () =>
  createSelector(
    (state: RootState) => state.alert.calendar,
    (_: RootState, resourceId: string) => resourceId,
    (calendar, resourceId) => calendar[resourceId] ?? null,
  );

export const makeMonthlyCalendarForResourceSelector = () =>
  createSelector(
    (state: RootState) => state.alert.monthlyCalendar,
    (_: RootState, resourceId: string) => resourceId,
    (calendar, resourceId) => calendar[resourceId] ?? [],
  );

export const makeStatusForDateSelector = () =>
  createSelector(
    makeMonthlyCalendarForResourceSelector(),
    (_: RootState, resourceId: string, date: Date) =>
      format(date, 'yyyy-MM-dd'),
    (calendar, dateString) => calendar[dateString] ?? null,
  );
