import React, { useCallback, useEffect, useRef, useState } from 'react';
// import { format } from 'date-fns';
import { FormikProps } from 'formik';

import { useDispatch } from 'react-redux';
import { useTypedSelector as useSelector } from '../../_redux/reducers';
import { pingPlansPageLoad } from '../../_redux/plans';

import PaymentDialog from '../../Components/Common/PaymentDialog';

import { Grid, useMediaQuery } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

import { selectAllPlans, selectPromo } from '../../_redux/plans/selectors';

import PlanItem from '../../Components/Common/PlanItem';

import { AlertFormValues } from './data';

const useStyles = makeStyles((theme) =>
  createStyles({
    cardsContainer: {
      margin: theme.spacing(2, 0),
    },
    cardGridItem: {
      display: 'flex',
    },
  }),
);

interface SelectPlanProps {
  form: FormikProps<AlertFormValues>;
  errorMessage: string;
}

const SelectPlan: React.FC<SelectPlanProps> = (props) => {
  const { form, errorMessage } = props;
  const { handleSubmit, setFieldValue, isSubmitting } = form;

  const isMobile = useMediaQuery('(max-width:600px)');
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();

  const tiers = useSelector(selectAllPlans);
  const promo = useSelector(selectPromo);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  useEffect(() => {
    dispatch(pingPlansPageLoad());
  }, [dispatch]);

  const [open, setOpen] = useState(false);
  const handleOpen = (interval: number) => {
    const tier = tiers.find((x) => x.interval === interval);
    if (!tier) return;
    if (tier.price === 0 || tier.promoPrice === 0) {
      onSubmit(interval);
    } else {
      setFieldValue('interval', interval);
      setOpen(true);
    }
  };
  const handleClose = () => {
    setFieldValue('interval', 60);
    setOpen(false);
  };

  const onSubmit = useCallback(
    async (int: number) => {
      if (int) setFieldValue('interval', int);
      handleSubmit();
    },
    [handleSubmit, setFieldValue],
  );

  return (
    <Grid container ref={ref}>
      <Grid
        container
        spacing={isMobile ? 2 : 5}
        alignItems="stretch"
        justifyContent="center"
        className={classes.cardsContainer}
      >
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            // md={4}
            sm={6}
            className={classes.cardGridItem}
          >
            <PlanItem
              plan={tier}
              promo={promo}
              disabled={isSubmitting}
              handleOpen={handleOpen}
            />
          </Grid>
        ))}
      </Grid>
      <PaymentDialog
        open={open}
        handleClose={handleClose}
        onSubmit={onSubmit}
        errorMessage={errorMessage}
      />
    </Grid>
  );
};

export default React.memo(SelectPlan);
