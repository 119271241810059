import * as Sentry from '@sentry/react';
import pkg from '../../package.json';

const API_BASE = process.env.REACT_APP_API_BASE + '/';

type anyObj = { [key: string]: any };
type anyPlainObj = { [key: string]: string };

interface Ioptions {
  endpoint: string;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH';
  token?: string;
  body?: anyObj | FormData;
  headers?: anyPlainObj;
}

function getFullUrl(endpoint: string): string {
  if (endpoint.startsWith('/')) return API_BASE + endpoint.slice(1);
  return API_BASE + endpoint;
}

function getBody(data?: anyObj | FormData): string | FormData | undefined {
  if (!data) return;
  if (data instanceof FormData) return data;
  return JSON.stringify(data);
}

function getHeaders(
  customHeaders: anyPlainObj,
  token: string | null,
  body?: string | FormData,
): anyPlainObj {
  const headers: anyPlainObj = {
    Accept: 'application/json',
    'Api-Version': pkg.version,
    'Content-Type': 'application/json',
  };
  if (body instanceof FormData) delete headers['Content-Type'];
  if (token) headers.Authorization = token;
  return { ...headers, ...customHeaders };
}

function hasError(response: Response): boolean {
  if (!response.ok) return true;
  if (response.status >= 400 && response.status < 600) return true;
  return false;
}

export default async function callApi<T extends object = { [x: string]: any }>(
  options: Ioptions,
): Promise<[T, boolean]> {
  const {
    endpoint,
    method = 'GET',
    body: data,
    token = null,
    headers: customHeaders = {},
  } = options;
  const url = getFullUrl(endpoint);
  const body = getBody(data);
  const headers = getHeaders(customHeaders, token, body);
  try {
    const response = await fetch(url, { method, headers, body });
    const jsonResponse = await response.json();
    if (hasError(response)) {
      Sentry.captureEvent({
        message: `${response.status} ${method}: ${endpoint}`,
        extra: { url, headers, method, body, jsonResponse },
      });
      return [jsonResponse, true];
    }
    return [jsonResponse, false];
  } catch (err) {
    Sentry.captureException(err);
    return [{ msg: String(err) }, true] as [T, true];
  }
}
