import { useRef, useCallback } from 'react';

export const useDebouncedCallback = <A extends Array<unknown>>(
  callback: (...args: A) => void,
  delay: number,
): [(...args: A) => void] => {
  const debouncedFunction = useRef(callback);
  debouncedFunction.current = callback;
  const functionTimeoutHandler = useRef<number>();

  const debouncedCallback = useCallback(
    (...args: A) => {
      clearTimeout(functionTimeoutHandler.current);
      functionTimeoutHandler.current = window.setTimeout(() => {
        debouncedFunction.current(...args);
      }, delay);
    },
    [delay],
  );

  return [debouncedCallback];
};

export default useDebouncedCallback;
