export const CHANGE_START_DATE = 'CHANGE_START_DATE';
export const CHANGE_END_DATE = 'CHANGE_END_DATE';

export const CHANGE_CITY = 'CHANGE_CITY';

export const REQUEST_CREATE_ALERT = 'REQUEST_CREATE_ALERT';
export const RECEIVE_CREATE_ALERT = 'RECEIVE_CREATE_ALERT';
export const ERROR_CREATE_ALERT = 'ERROR_CREATE_ALERT';

export const REQUEST_PATCH_ALERT = 'REQUEST_PATCH_ALERT';
export const RECEIVE_PATCH_ALERT = 'RECEIVE_PATCH_ALERT';
export const ERROR_PATCH_ALERT = 'ERROR_PATCH_ALERT';

export const SET_STRIPE_TOKEN = 'SET_STRIPE_TOKEN';

export const REQUEST_LIST_ALERTS = 'REQUEST_LIST_ALERTS';
export const RECEIVE_LIST_ALERTS = 'RECEIVE_LIST_ALERTS';
export const ERROR_LIST_ALERTS = 'ERROR_LIST_ALERTS';

export const RECEIVE_CALENDAR = 'RECEIVE_CALENDAR';
export const RECEIVE_MONTHLY_CALENDAR = 'RECEIVE_MONTHLY_CALENDAR';

export interface City {
  address: string;
  city?: string;
  label: string;
  parent_name?: string;
  resourceId: string;
  resourceName: string;
  state?: string;
  value: string;
}

export interface AlertCreatePayload {
  resourceId: string;
  // loops: string[];
  resources?: string[];
  start_date: string; // yyyy-MM-dd
  end_date: string; // yyyy-MM-dd
  interval: number;
  token?: string;
}
