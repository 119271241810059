import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { handleReset } from '../../_redux/actions/stepper';

import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
}));

export function Summary(props) {
  const classes = useStyles();
  const { handleReset } = props;

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const timeout = useRef(null);
  useEffect(() => {
    timeout.current = setTimeout(() => setShouldRedirect(true), 3000);
    return () => clearTimeout(timeout.current);
  }, []);

  if(shouldRedirect) return <Redirect to="/alerts" />;

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography align="center">
          Your Alert has been created succesfully
        </Typography>
        <Typography align="center">Redirecting to alerts list page</Typography>
      </Grid>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Button color="primary" onClick={handleReset}>
          Create New Alert
        </Button>
        <Button
          color="primary"
          variant="contained"
          to="/alerts"
          component={Link}
          className={classes.marginLeft}
        >
          View my Alerts
        </Button>
      </Grid>
    </Grid>
  );
}

Summary.propTypes = {
  handleReset: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  handleReset: () => dispatch(handleReset()),
});

export default connect(null, mapDispatchToProps)(Summary);
