import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface DetailMenuProps {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  loops?: { [x: string]: number };
}

const DetailMenu: React.FC<DetailMenuProps> = props => {
  const { anchorEl, handleClose, loops } = props;

  // const title = useMemo(() => {
  //   const { loops } = data;
  //   if (!loops) return '';
  //   return Object.keys(loops)
  //     .map(x => (loops[x] === 0 ? `${x}: Available` : `${x}: Unavailable`))
  //     .join('\n');
  // }, [data]);

  if (!loops) return null;
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {Object.keys(loops).map(key => (
        <MenuItem key={key} onClick={handleClose}>
          {key}: {loops[key] === 0 ? 'Available' : 'Unavailable'}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default React.memo(DetailMenu);
