import React, { useMemo } from 'react';
import { format } from 'date-fns';

// MUI
import { green, red } from '@mui/material/colors';
import {
  Fab,
  Tooltip,
} from '@mui/material';
import { makeStyles, withStyles, createStyles } from '@mui/styles';

import DetailMenu from './DetailMenu';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      margin: theme.spacing(0.2),
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  }),
);

interface CalendarItemProps {
  data: {
    date: Date;
    loops?: { [x: string]: number };
  };
}

const CalendarItem: React.FC<CalendarItemProps> = props => {
  const classes = useStyles(props);
  const { data } = props;
  const { loops } = data;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const dateDisplay = useMemo(() => format(data.date, 'MM/dd'), [data.date]);
  const isAavailable = useMemo(() => {
    if (!loops) return;
    if (Object.keys(loops).some(x => loops[x] === 0)) return 0;
    if (Object.keys(loops).every(x => loops[x] === 1)) return 1;
  }, [loops]);

  return (
    <>
      <DetailMenu anchorEl={anchorEl} handleClose={handleClose} loops={loops} />
      <Tooltip title="Click for details">
        <div className={classes.root}>
          {isAavailable === 0 && (
            <GreenFab size="small" onClick={handleOpen}>
              {dateDisplay}
            </GreenFab>
          )}
          {isAavailable === 1 && (
            <RedFab size="small" onClick={handleOpen}>
              {dateDisplay}
            </RedFab>
          )}
        </div>
      </Tooltip>
    </>
  );
};

export default React.memo(CalendarItem);

const GreenFab = withStyles(theme =>
  createStyles({
    root: {
      // color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    label: {
      fontSize: theme.typography.caption.fontSize,
    },
  }),
)(Fab);

const RedFab = withStyles(theme =>
  createStyles({
    root: {
      // color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[300],
      '&:hover': {
        backgroundColor: red[500],
      },
    },
    label: {
      fontSize: theme.typography.caption.fontSize,
    },
  }),
)(Fab);
